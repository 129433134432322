import Vue from 'vue';

Vue.filter('fixedNumber', (value: number, precision: number) => {
  if (value === 0) {
    return 0;
  }

  if (value) {
    if (precision === undefined || precision === null) {
      precision = 2;
    }

    return parseFloat('' + Math.round(value * 100) / 100).toLocaleString(
      'de-de',
      {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      }
    );
  }
});
