import createPersistedState from 'vuex-persistedstate';
import store from '@/store';

createPersistedState({
  key: 'vuex-state',
  paths: [
    'auth.accessToken',
    'auth.refreshToken',
    'auth.userId',
    'auth.username'
  ]
})(store);
