import { MyProfile } from '@/pages';
import { RouteConfig } from 'vue-router';
import MyProfileIndex from '@/pages/my-profile/Index.vue';
import MyProfilePasswordIndex from '@/pages/my-profile/Password.vue';

const routeConfig: RouteConfig = {
  path: '/myprofile',
  component: MyProfile,
  children: [
    {
      path: '',
      component: MyProfileIndex,
      redirect: 'password',
      children: [
        {
          path: 'password',
          component: MyProfilePasswordIndex
        }
      ]
    }
  ]
};
export default routeConfig;
