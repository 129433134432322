
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsInputCheckbox extends Vue {
  @ModelSync('value', 'changed', {
    required: false,
    default: false,
    type: Boolean
  })
  internalValue!: boolean;

  @Prop({ required: false, default: false })
  value!: boolean;

  @Prop({ required: false, default: false })
  readonly!: boolean;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: '' })
  label!: string;

  @Prop({ required: false, default: 'primary' })
  color!: string;

  @Prop({ required: false, default: undefined })
  offIcon!: any;

  @Prop({ required: false, default: undefined })
  onIcon!: any;

  @Prop({ required: false, default: false })
  biggerRadioButtonsHoverRatio!: boolean;

  onClick() {
    if (!this.disabled && !this.readonly) {
      this.internalValue = !this.internalValue;
      this.$emit('click', this.internalValue);
    }
  }
}
