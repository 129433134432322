
import { Component, Vue } from 'vue-property-decorator';
import OsCard from '@/components/core/card/OsCard.vue';
import OsLanguageSelection from '@/components/app/OsLanguageSelection.vue';
@Component({
  components: { OsLanguageSelection, OsCard }
})
export default class OsProfileIconPopover extends Vue {
  menu = false;

  async logout() {
    await this.$store.dispatch('auth/logout');
    await this.$router.push('/');
  }

  async flagClicked(event: any) {
    await this.$store.dispatch('user/setLanguageCode', event);
  }
}
