
import { Component, Prop, Vue } from 'vue-property-decorator';
import { customersApi } from '@/apis';
import OsButton from '@/components/core/OsButton.vue';
@Component({
  components: { OsButton }
})
export default class OsLogoUploader extends Vue {
  @Prop({ default: true })
  showDeleteButton!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  file: Blob | null = null;

  loading = false;

  async onFileChanged() {
    if (!this.file) {
      return;
    }

    if (!'image/png, image/jpeg'.includes(this.file.type)) {
      this.showErrorToast(
        this.$tc('form.company.change_logo_button.error_text.unsupported_type')
      );
      return;
    }

    if (this.file.size > 5000000) {
      this.showErrorToast(
        this.$tc(
          'form.company.change_logo_button.error_text.file_size_exceeded'
        )
      );
      return;
    }

    this.loading = true;

    let formData = new FormData();
    formData.append('file', this.file);

    const logoUrl = await customersApi.uploadCustomerLogo(
      this.$store.state.user.customer.id,
      formData
    );

    this.$emit('logo-changed', logoUrl);
    this.loading = false;
  }

  showErrorToast(text: string) {
    this.$global.dialogs.toast(text, {
      color: 'error',
      icon: 'mdi-alert-circle-outline'
    });
  }

  async onDeleteClicked() {
    const result = await this.$global.dialogs.confirm(
      this.$tc('form.company.change_logo_button.delete_dialog.title'),
      this.$tc('form.company.change_logo_button.delete_dialog.message')
    );

    if (result) {
      await customersApi.deleteLogo(this.$store.state.user.customer.id);
      this.file = null;
      this.$emit('logo-changed', null);
    }
  }
}
