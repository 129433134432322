
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsToolbar extends Vue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: null })
  titleTo!: string;

  onTitleClicked() {
    if (!this.titleTo) {
      return;
    }

    this.$router.push(this.titleTo);
  }
}
