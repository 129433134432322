import store from '@/store';
import appModule from '@/store/AppModule';
import _ from 'lodash';

export class AppDirtyUtil {
  // static global functions
  static get isDirty(): boolean {
    return appModule.state.isDirty;
  }

  static setDirty(v: boolean) {
    store.commit('app/setIsDirty', v);
  }

  // instance
  public isInstanceDirty = false;
  private originalObject: any = null;
  private ignoreFields: string[] = [];

  init(origin: any, ignoreFields: string[] = []) {
    this.ignoreFields = ignoreFields;
    AppDirtyUtil.setDirty(false);
    this.isInstanceDirty = false;
    setTimeout(() => {
      if (Array.isArray(origin)) {
        this.originalObject = _.cloneDeep(origin);
      } else {
        this.originalObject = _.clone(origin);
      }

      AppDirtyUtil.setDirty(false);
      this.isInstanceDirty = false;
    }, 100);
  }

  getOriginalObject(): any {
    return this.originalObject;
  }

  update(current: any) {
    if (Array.isArray(current)) {
      if (current && this.originalObject) {
        const currentCopySorted = _.cloneDeep(current);

        const isDirty = !_.isEqual(currentCopySorted, this.originalObject);
        this.isInstanceDirty = isDirty;

        AppDirtyUtil.setDirty(isDirty);
      }
    } else {
      const isDirty = !_.isEqual(
        _.omit(current, this.ignoreFields),
        _.omit(this.originalObject, this.ignoreFields)
      );

      this.isInstanceDirty = isDirty;
      AppDirtyUtil.setDirty(isDirty);
    }
  }
}
