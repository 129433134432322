
import { Component, Vue } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsLogo from '@/components/core/OsLogo.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';
import { IRegisterRequest } from '@/models/authentication/IRegisterRequest';
@Component({
  components: {
    OsInputCheckbox,
    OsAlertBox,
    OsButton,
    OsToolbar,
    OsLogo,
    OsSheetContent,
    OsEntityForm,
    OsEntityToolbar
  }
})
export default class OsRegistrationForm extends Vue {
  registerRequest: IRegisterRequest = {
    languagecode: 'en',
    companyName: '',
    companyTelephone: '',
    userEmailaddress: '',
    userFirstname: '',
    userLastname: ''
  };

  userDataValid = false;
  companyDataValid = false;
  conditionsAccepted = false;

  get formIsValid(): boolean {
    return (
      this.conditionsAccepted && this.userDataValid && this.companyDataValid
    );
  }

  loading = false;

  get userFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: this.$tc('form.registration.user.firstname'),
        propName: 'userFirstname',
        xsCols: 12,
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.$tc('form.registration.user.lastname'),
        propName: 'userLastname',
        xsCols: 12,
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.email,
        label: this.$tc('form.registration.user.email'),
        propName: 'userEmailaddress',
        xsCols: 12,
        smCols: 12,
        required: true
      }
    ];
  }

  get companyFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: this.$tc('form.registration.company.name'),
        propName: 'companyName',
        xsCols: 12,
        smCols: 8,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.$tc('form.registration.company.telephone'),
        propName: 'companyTelephone',
        xsCols: 12,
        smCols: 4,
        required: false
      }
    ];
  }

  onSave() {
    this.$emit('save', this.registerRequest);
  }
}
