import { RouteConfig } from 'vue-router';
import ExternalTargetProfile from '@/pages/external/TargetProfile.vue';
import External from '@/pages/External.vue';
import ExternalTargetProfileIndex from '@/pages/external/TargetProfile/Index.vue';
import ExternalTargetProfileCreate from '@/pages/external/TargetProfile/Create.vue';
import ExternalTargetProfileFinished from '@/pages/external/TargetProfile/Finished.vue';

const routeConfig: RouteConfig = {
  path: '/external',
  component: External,
  meta: {
    anonymousAllowed: true
  },
  children: [
    {
      path: 'target-profile',
      component: ExternalTargetProfile,
      meta: {
        anonymousAllowed: true
      },
      children: [
        {
          path: '',
          name: 'ExternalTargetProfileIndex',
          component: ExternalTargetProfileIndex,
          meta: {
            anonymousAllowed: true
          }
        },
        {
          path: 'create',
          name: 'ExternalTargetProfileCreate',
          component: ExternalTargetProfileCreate,
          meta: {
            anonymousAllowed: true
          }
        },
        {
          path: 'finished',
          name: 'ExternalTargetProfileFinished',
          component: ExternalTargetProfileFinished,
          meta: {
            anonymousAllowed: true
          }
        }
      ]
    }
  ]
};
export default routeConfig;
