
import { Component, Vue, Watch } from 'vue-property-decorator';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import { IMenuItem } from '@/models/navigation';
import { customersApi } from '@/apis';
import { ICampaign } from '@/models/application';
import userModule from '@/store/UserModule';
@Component({
  components: { OsAppSubMenu, OsSubMenuPage }
})
export default class Campaigns extends Vue {
  campaignId: string | null = null;
  campaign: ICampaign | null = null;
  customerId!: string;

  get items(): IMenuItem[] {
    return [
      {
        label: this.$tc('page.campaign.menu.overview.title'),
        subLabel: this.$tc('page.campaign.menu.overview.sub_text'),
        to: `/campaigns`,
        exact: true
      }
    ];
  }

  created() {
    this.customerId = userModule.state.customer!.id;
  }

  @Watch('$route.params')
  onRouteParamsChanged() {
    this.getCampaign();
  }

  async mounted() {
    await this.getCampaign();
  }

  async getCampaign() {
    let currentCampaignId = this.$route.params.campaignid;
    if (currentCampaignId == this.campaignId) {
      return;
    }
    this.campaignId = currentCampaignId;

    if (!this.campaignId) {
      this.campaign = null;
      return;
    }

    this.campaign = await customersApi.getCampaignById(
      this.customerId,
      this.campaignId
    );
  }
}
