import { GetterTree, MutationTree, ActionTree } from 'vuex';

class State {
  isDirty = false;
  langDebug = false;
}

const mutations = <MutationTree<State>>{
  setIsDirty(state, payload: boolean) {
    state.isDirty = payload;
  },
  setLangDebug(state, payload: boolean) {
    state.langDebug = payload;
  }
};

const actions = <ActionTree<State, any>>{};

const getters = <GetterTree<State, any>>{};

const AppModule = {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AppModule;
