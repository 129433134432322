
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';
import {
  Gender,
  IParticipant,
  ISurveyCustomField,
  ISurveyCustomFieldAnswer
} from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';

@Component({
  components: { OsInputCheckbox, OsEntityForm }
})
export default class OsSurveyStepIntroData extends Vue {
  @PropSync('participant', { required: true })
  internalParticipant!: IParticipant;

  @Prop({ required: true, default: [] })
  customFields!: ISurveyCustomField[];

  get hasCustomFields(): boolean {
    return this.customFields && this.customFields.length > 0;
  }

  get orderedCustomFields(): ISurveyCustomField[] {
    return this.customFields.sort(
      (a, b) => a.sequencenumber - b.sequencenumber
    );
  }

  cFieldAnswers: any = {};
  checked = false;
  formIsValid = false;
  formCustomFieldsIsValid = false;
  customFieldAnswersReady = false;

  fields: IEntityFormField[] = [
    {
      type: EntityFormFieldType.radio,
      required: true,
      label: '',
      propName: 'gender',
      mdCols: 12,
      radioAsRow: true,
      items: [
        {
          key: this.$tc(
            'page.survey.intro.participant_data.gender_option.male'
          ),
          value: Gender.Male
        },
        {
          key: this.$tc(
            'page.survey.intro.participant_data.gender_option.female'
          ),
          value: Gender.Female
        }
      ]
    },
    {
      type: EntityFormFieldType.text,
      required: true,
      label: this.$tc('page.survey.intro.participant_data.firstname'),
      propName: 'firstname',
      mdCols: 6
    },
    {
      type: EntityFormFieldType.text,
      required: true,
      label: this.$tc('page.survey.intro.participant_data.lastname'),
      propName: 'lastname',
      mdCols: 6
    },
    {
      type: EntityFormFieldType.email,
      required: true,
      label: this.$tc('page.survey.intro.participant_data.email'),
      propName: 'emailaddress',
      mdCols: 6
    },
    {
      type: EntityFormFieldType.text,
      required: false,
      label: this.$tc('page.survey.intro.participant_data.company'),
      propName: 'companyname',
      mdCols: 6
    }
  ];

  get cFields(): IEntityFormField[] {
    if (!this.hasCustomFields) {
      return [];
    }

    const fields = this.orderedCustomFields.map((cf) => {
      return {
        type: EntityFormFieldType.text,
        propName: `${cf.id}`,
        required: true,
        label: cf.labels.find((l) => l.languagecode === this.$i18n.locale)
          ?.text,
        xsCols: 12
      } as IEntityFormField;
    });

    return fields ?? [];
  }

  @Watch('checked')
  onCheckedChanged() {
    this.updateIsValid();
  }

  @Watch('formIsValid')
  onFormIsValidChanged() {
    this.updateIsValid();
  }

  @Watch('formCustomFieldsIsValid')
  onFormCustomFieldsIsValid() {
    this.updateIsValid();
  }

  getCustomFieldsAsAnswers(participantId: string): ISurveyCustomFieldAnswer[] {
    return Object.keys(this.cFieldAnswers).map((aKey) => {
      return {
        id: GuidUtil.empty(),
        surveyCustomFieldId: aKey,
        participantId: participantId,
        value: this.cFieldAnswers[aKey]
      };
    });
  }

  mounted() {
    let customFieldAnswersObject: any = {};
    this.customFields.forEach((cf) => {
      customFieldAnswersObject[`${cf.id}`] = '';
    });
    this.cFieldAnswers = customFieldAnswersObject;
    this.customFieldAnswersReady = true;

    this.updateIsValid();
  }

  updateIsValid() {
    this.$emit(
      'update:valid',
      this.checked &&
        this.formIsValid &&
        (!this.hasCustomFields || this.formCustomFieldsIsValid)
    );
  }
}
