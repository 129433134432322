
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ILink, LinkType } from '@/models/application';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import { customersApi } from '@/apis';
import OsCreateLinksForm from '@/components/shared/links/OsCreateLinksForm.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsCloseButton from '@/components/core/OsCloseButton.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';
import { FormatterUtil } from '@/utils/FormatterUtil';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';
import { WindowUtil } from '@/utils/WindowUtil';

@Component({
  components: {
    OsInputCheckbox,
    OsSheetHeader,
    OsToolbar,
    OsCloseButton,
    OsSheetContent,
    OsSheet,
    OsCreateLinksForm,
    OsDatatable
  }
})
export default class OsLinksOverview extends Vue {
  @Prop({ required: true })
  customerId!: string;
  @Prop({ required: true })
  campaignId!: string;

  @Prop({ default: 'Links' })
  textTitle!: string;
  @Prop({ default: 'create new links' })
  textCreateNewLink!: string;
  @Prop({ default: 'Filter' })
  textFilterHeader!: string;
  @Prop({ default: 'hide not available links' })
  textHideNotAvailableLinks!: string;
  @Prop({ default: 'Copy' })
  textCopy!: string;
  @Prop({ default: 'link copied to clipboard' })
  textNotificationCopySuccess!: string;
  @Prop({ default: 'confirm deletion' })
  linkDeletionTitle!: string;
  @Prop({ default: 'Are you sure you want to delete this link?' })
  linkDeletionText!: string;
  @Prop({ default: 'link deleted' })
  linkDeletionSuccess!: string;
  @Prop({ default: 'remove unused links' })
  linksDeletionButtonTitle!: string;
  @Prop({ default: 'confirm deletion' })
  linksDeletionTitle!: string;
  @Prop({
    default:
      'Are you sure you want to delete all unused individual and global links?'
  })
  linksDeletionText!: string;
  @Prop({ default: 'links deleted' })
  linksDeletionSuccess!: string;
  @Prop({ default: 'Url' })
  textUrl!: string;
  @Prop({ default: 'Type' })
  textType!: string;
  @Prop({ default: 'Individual' })
  textTypeOptionIndividual!: string;
  @Prop({ default: 'Global' })
  textTypeOptionGlobal!: string;
  @Prop({ default: 'Participants' })
  textParticipants!: string;
  @Prop({ default: 'Available' })
  textAvailable!: string;

  $refs!: {
    createLinksForm: OsCreateLinksForm;
  };

  showCreateDialog = false;
  filterNotAvailableLinks = false;
  links: ILink[] = [];

  get headers(): IExtendedDataTableHeader[] {
    return [
      {
        text: this.textCopy,
        value: 'id',
        icon: 'mdi-content-copy',
        width: '15px',
        sortable: false,
        visible: (l: ILink) => {
          return l.isAvailable;
        },
        action: async (l: ILink) => {
          const link = this.buildSurveyUrl(l.token);
          const result = await this.$copyText(link);

          if (link === result.text) {
            this.$global.dialogs.toast(this.textNotificationCopySuccess, {
              color: 'success',
              icon: 'mdi-content-copy'
            });
          }
        },
        align: 'center'
      },
      {
        text: this.textUrl,
        value: 'token',
        formatter: (token: string, item: ILink) => {
          if (item.isAvailable) {
            return `<span>${this.buildSurveyUrl(token)}</span>`;
          }
          return `<span class="text-decoration-line-through">${this.buildSurveyUrl(
            token
          )}</span>`;
        }
      },
      {
        text: this.textType,
        value: 'type',
        width: '20px',
        icon: (t: LinkType): string => {
          if (t === LinkType.Global) {
            return 'mdi-account-group-outline';
          }

          return 'mdi-target-account';
        },
        sortable: false,
        align: 'center',
        action: (l: ILink) => {
          this.$emit('onTypeClicked', l);
        }
      },
      {
        text: '',
        width: '10px',
        value: 'type',
        formatter: (v) => {
          switch (v) {
            case LinkType.Individual:
              return this.textTypeOptionIndividual;
            case LinkType.Global:
              return this.textTypeOptionGlobal;
          }
          return '';
        }
      },
      {
        text: this.textParticipants,
        value: 'participantId',
        width: '100px',
        sortable: false
      },
      {
        text: this.textAvailable,
        value: 'isAvailable',
        formatter: FormatterUtil.boolToHumanStringFormatter,
        width: '105px'
      },
      {
        text: '',
        value: 'id',
        icon: 'mdi-delete-outline',
        width: '15px',
        sortable: false,
        visible: (l: ILink) => {
          if (!this.$global.roles.isAdmin()) {
            return false;
          }

          return !l.participantId && l.finishes === 0;
        },
        action: async (l: ILink) => {
          if (
            await this.$global.dialogs.confirm(
              this.linkDeletionTitle,
              this.linkDeletionText
            )
          ) {
            const result = await customersApi.deleteCampaignLink(
              this.customerId,
              this.campaignId,
              l.id
            );
            if (result) {
              await this.loadCampaignLinks();
              this.$global.dialogs.toast(this.linkDeletionSuccess, {
                color: 'success',
                icon: 'mdi-delete-outline'
              });
            }
          }
        },
        align: 'center'
      }
    ];
  }

  get extraButtons(): any[] {
    if (!this.$global.roles.isAdmin()) {
      return [];
    }

    return [
      {
        title: this.linksDeletionButtonTitle,
        icon: 'mdi-delete-outline',
        enabled: true,
        action: async () => {
          if (
            await this.$global.dialogs.confirm(
              this.linksDeletionTitle,
              this.linksDeletionText
            )
          ) {
            const result = await customersApi.deleteUnusedCampaignLinks(
              this.customerId,
              this.campaignId
            );
            if (result) {
              await this.loadCampaignLinks();
              this.$global.dialogs.toast(this.linksDeletionSuccess, {
                color: 'success',
                icon: 'mdi-delete-outline'
              });
            }
          }
        }
      }
    ];
  }

  get filteredLinks(): ILink[] {
    if (this.filterNotAvailableLinks) {
      return this.links.filter((l) => l.isAvailable);
    }
    return this.links;
  }

  async mounted() {
    await this.loadCampaignLinks();
  }

  async loadCampaignLinks() {
    this.links = await customersApi.getCampaignLinks(
      this.customerId,
      this.campaignId
    );
  }
  goToParticipant(id: string) {
    this.$emit('goToParticipant', id);
  }

  getItemClass(item: ILink) {
    if (!item.isAvailable) {
      return 'non-available-row';
    }
    return '';
  }

  buildSurveyUrl(token: string) {
    return `${WindowUtil.getBaseUrl()}/survey?token=${token}`;
  }

  onLinksCreate() {
    if (this.$refs.createLinksForm) {
      this.$refs.createLinksForm.reset();
    }

    this.showCreateDialog = true;
  }

  onLinksCreated(links: ILink[]) {
    this.links.push(...links);
    this.showCreateDialog = false;
  }
}
