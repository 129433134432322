
import { Component, Vue } from 'vue-property-decorator';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsProfileIconPopover from '@/components/app/OsProfileIconPopover.vue';
import OsGlobalSearch from '@/components/app/OsGlobalSearch.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
@Component({
  components: {
    OsAlertBox,
    OsGlobalSearch,
    OsProfileIconPopover,
    OsButton,
    OsInputTextField
  }
})
export default class OsAppBar extends Vue {}
