
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class OsSearchField extends Vue {
  @PropSync('search', { required: true })
  internalSearchValue!: string;
  @Prop({ default: 'search...' })
  label!: string;
}
