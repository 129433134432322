
import { Component, Vue } from 'vue-property-decorator';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import { targetprofilesApi } from '@/apis';

@Component({
  components: { OsAlertBox, OsLoadingIndicator }
})
export default class ExternalTargetProfileIndex extends Vue {
  token: string | null = null;
  loading = true;
  tokenError = false;

  async mounted() {
    this.token = this.$route.query?.token as string;

    if (!this.token) {
      this.tokenError = true;
      this.loading = false;
      return;
    }

    try {
      const result = await targetprofilesApi.getByExternalToken(this.token);

      if (!result) {
        this.tokenError = true;
        return;
      }
      await this.$router.push(
        `/external/target-profile/create?token=${this.token}`
      );
    } catch {
      this.tokenError = true;
    } finally {
      this.loading = false;
    }
  }
}
