
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class OsPasswordInformationItem extends Vue {
  @Prop({
    default: false
  })
  valid!: boolean;
}
