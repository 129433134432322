import { BaseApi } from '@/apis/BaseApi';
import { IRegisterRequest } from '@/models/authentication/IRegisterRequest';
import { IRegisterResponse } from '@/models/authentication/IRegisterResponse';

export class RegistrationApi extends BaseApi {
  constructor() {
    super('registration');
  }

  async registerTrialAccount(
    request: IRegisterRequest
  ): Promise<IRegisterResponse> {
    return (
      await this.post<IRegisterResponse>(
        '/registertrialaccount',
        request,
        false,
        false
      )
    ).result;
  }
}
