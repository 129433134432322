
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import { IKeyValuePair } from '@/models/core';
import { ILookupAsRadio } from '@/models/core/ILookupAsRadio';
import { AdvancedRadioItem } from '@/models/core/AdvancedRadioItem';

@Component
export default class OsInputRadioGroup extends Vue {
  @ModelSync('value', 'onModelChanged', { default: '' })
  internalValue!: any;

  @Prop({ required: true })
  items!:
    | IKeyValuePair<string, string | null>[]
    | ILookupAsRadio[]
    | AdvancedRadioItem[];

  @Prop({ required: false, default: false })
  readonly!: string;

  @Prop({ required: false, default: '' })
  label!: string;

  @Prop({ required: false, default: false })
  required!: boolean;

  @Prop({ required: false, default: false })
  row!: boolean;

  get rules(): any {
    const rules = [];

    if (this.required) {
      rules.push((v: string) => !!v || 'Pflichtfeld'); // todo localization
    }

    return rules;
  }
}
