import { render, staticRenderFns } from "./Questions.vue?vue&type=template&id=1f0a5a20&scoped=true"
import script from "./Questions.vue?vue&type=script&lang=ts"
export * from "./Questions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f0a5a20",
  null
  
)

export default component.exports