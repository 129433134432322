
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { IMenuItem } from '@/models/navigation';

@Component
export default class OsAppSubMenu extends Vue {
  @PropSync('menuItems', { required: true })
  menuItemsInternal!: IMenuItem[];

  @Prop()
  tabLayout!: boolean;
}
