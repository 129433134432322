import { SetPassword } from '@/pages';
import { RouteConfig } from 'vue-router';
import SetPasswordIndex from '@/pages/set-password/Index.vue';
import SetPasswordTokenIndex from '@/pages/set-password/_token/Index.vue';

const routeConfig: RouteConfig = {
  path: '/setpassword',
  component: SetPassword,
  meta: {
    anonymousAllowed: true
  },
  children: [
    {
      path: '',
      name: 'SetPassword',
      component: SetPasswordIndex,
      meta: {
        anonymousAllowed: true
      },
      children: []
    },
    {
      path: ':token',
      name: 'SetPasswordToken',
      component: SetPasswordTokenIndex,
      meta: {
        anonymousAllowed: true
      }
    }
  ]
};
export default routeConfig;
