
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class OsCard extends Vue {
  @Prop()
  maxWidth!: string;

  @Prop({ default: '8' })
  elevation!: string;
}
