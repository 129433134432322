
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { ICampaign, Recipientgroup } from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
@Component({
  components: { OsToolbar, OsSheetContent, OsEntityForm, OsEntityToolbar }
})
export default class OsCampaignForm extends Vue {
  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;
  @PropSync('value', { required: true })
  internalValue!: ICampaign;
  @PropSync('loading', { default: false })
  internalLoading!: boolean;
  @Prop({ required: false, default: false })
  isCreateForm!: boolean;

  @Prop({ required: true })
  customerId!: string;
  @Prop({ default: 'Create new Campaign' })
  titleCreateForm!: string;
  @Prop({ default: 'Campaign' })
  titleExistingForm!: string;
  @Prop({ default: 'Title' })
  textTitle!: string;
  @Prop({ default: 'Questionnaire' })
  textQuestionnaire!: string;
  @Prop({ default: 'Which questionnaire?' })
  textToolbarQuestionnaire!: string;
  @Prop({ default: 'Who should receive the result?' })
  textToolbarRecipientGroup!: string;
  @Prop({ default: 'Company & participants' })
  textRecipientgroupAll!: string;
  @Prop({ default: 'Only your company' })
  textRecipientgroupStaff!: string;
  @Prop({ default: 'Only the participants' })
  textRecipientgroupParticipants!: string;

  mainFieldsAreValid = false;
  questionnaireFieldsAreValid = false;
  recipientFieldsAreValid = false;

  get mainFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: this.textTitle,
        propName: 'title',
        smCols: 12,
        required: true
      }
    ];
  }
  get questionnaireFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.lookup,
        lookupAsRadio: true,
        label: '',
        propName: 'questionnaireId',
        smCols: 12,
        required: true,
        entityName: 'questionnaire',
        readonly: !this.isCreateForm
      }
    ];
  }
  get recipientFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.radio,
        label: '',
        propName: 'recipientgroup',
        smCols: 12,
        required: true,
        readonly:
          !this.$global.roles.isAdmin() && !this.$global.roles.isAdmin(),
        items: [
          {
            key: this.textRecipientgroupAll,
            value: Recipientgroup.All
          },
          {
            key: this.textRecipientgroupStaff,
            value: Recipientgroup.Staff
          },
          {
            key: this.textRecipientgroupParticipants,
            value: Recipientgroup.Participants
          }
        ]
      }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('onSave');
  }

  onDelete() {
    this.$emit('onDelete');
  }

  onUndo() {
    this.$emit('undoClicked');
  }

  mounted() {
    this.internalAppDirtyUtil.init(this.internalValue);
  }

  @Watch('internalValue', { deep: true })
  onInternalValueChanged() {
    this.internalAppDirtyUtil.update(this.internalValue);
  }
}
