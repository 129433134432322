
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsDatatable from '@/components/core/OsDatatable.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsCard from '@/components/core/card/OsCard.vue';
import OsCardText from '@/components/core/card/OsCardText.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsCloseButton from '@/components/core/OsCloseButton.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsInputLookup from '@/components/core/input/OsInputLookup.vue';

@Component({
  components: {
    OsInputLookup,
    OsButton,
    OsCloseButton,
    OsToolbar,
    OsCardText,
    OsCard,
    OsSheetContent,
    OsSheet,
    OsDatatable
  }
})
export default class OsSelectCampaignDialog extends Vue {
  @Prop({ required: true, default: null })
  value!: string | null;

  internalValue: string | null = null;

  @Watch('value')
  onValueChanged() {
    this.internalValue = this.value;
  }

  @Watch('internalValue')
  onInternalValueChanged() {
    this.$emit('input', this.internalValue);
  }

  @PropSync('visible', { default: false })
  internalVisible!: boolean;

  @PropSync('loading', { default: false })
  internalLoading!: boolean;

  @Prop({ default: 'Select campaign' })
  titleText!: string;

  @Prop({ default: 'Select campaign' })
  btnAcceptText!: string;

  @Prop({ default: 'Campaign' })
  campaignLabelText!: string;
}
