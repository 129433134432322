
import { Component, Vue, Watch } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/app/DefaultLayout.vue';
import LoginLayout from '@/layouts/app/LoginLayout.vue';
import SurveyLayout from '@/layouts/app/SurveyLayout.vue';
import { LanguageUtil } from '@/utils/LanguageUtil';
import ExternalLayout from '@/layouts/app/ExternalLayout.vue';
import store from '@/store';

@Component({
  components: { DefaultLayout, LoginLayout, SurveyLayout, ExternalLayout }
})
export default class App extends Vue {
  layout: string | null = null;

  mounted() {
    this.onLanguagecodeChanged('en');
    this.onRouteChanged();

    if (this.$route.query.lang) {
      if (this.$route.query.lang === 'debug') {
        store.commit('app/setLangDebug', true);
      }
    }
  }

  @Watch('$route')
  onRouteChanged() {
    const path = this.$route.path;

    if (
      path.startsWith('/login') ||
      path.startsWith('/setpassword') ||
      path.startsWith('/register')
    ) {
      if (this.layout !== 'login-layout') {
        this.layout = 'login-layout';
      }
    } else if (path.startsWith('/survey')) {
      if (this.layout !== 'survey-layout') {
        this.layout = 'survey-layout';
      }
    } else if (path.startsWith('/external')) {
      if (this.layout !== 'external-layout') {
        this.layout = 'external-layout';
      }
    } else {
      if (this.layout !== 'default-layout') {
        this.layout = 'default-layout';
      }
    }
  }

  @Watch('$store.state.user.appuser.languagecode')
  onLanguagecodeChanged(v: string | null | undefined) {
    if (v === undefined || v === null || v.length === 0) {
      LanguageUtil.setLanguageAsync('en');
    } else {
      LanguageUtil.setLanguageAsync(v);
    }
  }
}
