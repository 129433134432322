
import { Component, Vue } from 'vue-property-decorator';
import OsConfirmDialog from '@/components/core/OsConfirmDialog.vue';
import OsInformDialog from '@/components/core/OsInformDialog.vue';
import OsSnackBar from '@/components/core/OsSnackBar.vue';
import OsBusinessErrorDialog from '@/components/core/OsBusinessErrorDialog.vue';
@Component({
  components: {
    OsBusinessErrorDialog,
    OsSnackBar,
    OsInformDialog,
    OsConfirmDialog
  }
})
export default class OsGlobalDialogs extends Vue {
  $refs!: {
    confirm: OsConfirmDialog;
    info: OsInformDialog;
    snackbar: OsSnackBar;
    businessError: OsBusinessErrorDialog;
  };

  mounted() {
    this.$global.dialogs.setConfirmDialog(this.$refs.confirm);
    this.$global.dialogs.setInfoDialog(this.$refs.info);
    this.$global.dialogs.setSnackBar(this.$refs.snackbar);
    this.$global.dialogs.setBusinessErrorDialogRef(this.$refs.businessError);
  }
}
