
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsTargetProfileForm from '@/components/shared/target-profiles/OsTargetProfileForm.vue';
import { StringValidationUtil } from '@/utils/StringValidationUtil';
@Component({
  components: {
    OsSheet,
    OsTargetProfileForm
  }
})
export default class CampaignTargetProfileById extends Vue {
  @Prop({ required: false, default: '' })
  campaignId!: string;

  @Prop({ required: false })
  targetProfilesOverviewUrl!: string;

  @Prop({ required: false, default: false })
  showCampaignLookup!: boolean;

  internalCampaignId!: string;

  async created() {
    if (StringValidationUtil.isNullOrWhitespace(this.campaignId)) {
      this.internalCampaignId = this.$route.params.campaignid;
    } else {
      this.internalCampaignId = this.campaignId;
    }
  }

  async recordDeleted() {
    if (
      !StringValidationUtil.isNullOrWhitespace(this.targetProfilesOverviewUrl)
    ) {
      await this.$router.push(this.targetProfilesOverviewUrl);
    } else {
      await this.$router.push(
        `/campaigns/${this.internalCampaignId}/target-profiles`
      );
    }
  }
}
