
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import { customersApi } from '@/apis';
import { LinkType } from '@/models/application';

@Component({
  components: { OsButton, OsInputTextField, OsSheet, OsEntityForm }
})
export default class OsCreateLinksForm extends Vue {
  @Prop({ required: true })
  customerId!: string;
  @Prop({ required: true })
  campaignId!: string;

  @Prop({ default: 'Global link' })
  textGlobalButtonTitle!: string;
  @Prop({
    default:
      '<span class="font-weight-bold">one link</span>, usable by <br /><span class="font-weight-bold">multiple participants</span>'
  })
  textGlobalButtonSubText!: string;
  @Prop({ default: 'Individual link' })
  textIndividualButtonTitle!: string;
  @Prop({
    default:
      '<span class="font-weight-bold">several links</span>,<br />each of only <span class="font-weight-bold">once per participant</span>'
  })
  textIndividualButtonSubText!: string;
  @Prop({ default: 'Number of links to generate' })
  textQuantity!: string;
  @Prop({ default: 'create link' })
  textCreateLink!: string;
  @Prop({ default: 'create links' })
  textCreateLinks!: string;
  @Prop({ default: 'create new links' })
  confirmIndividualLinksCreationTitle!: string;
  @Prop({ default: `Are you sure you want to create {0} links?` })
  confirmIndividualLinksCreationMessage!: string;

  selectedIndexType: number | undefined = -1;
  quantity = 1;
  loading = false;

  get isIndividualLink(): boolean {
    return this.selectedIndexType === 1;
  }

  get isValidForm() {
    if (this.selectedIndexType === 0) {
      return true;
    }

    if (this.selectedIndexType === 1) {
      return this.quantity > 0;
    }

    return false;
  }

  reset() {
    this.selectedIndexType = -1;
    this.quantity = 1;
  }

  async onSubmit() {
    if (this.isValidForm) {
      this.loading = true;
      try {
        if (
          this.isIndividualLink &&
          this.quantity > 100 &&
          !(await this.$global.dialogs.confirm(
            this.confirmIndividualLinksCreationTitle,
            this.$t(this.confirmIndividualLinksCreationMessage, {
              linkCount: this.quantity.toString()
            }) as string
          ))
        ) {
          return;
        }

        const result = await customersApi.createCampaignLinks(
          this.customerId,
          this.campaignId,
          {
            type: this.isIndividualLink ? LinkType.Individual : LinkType.Global,
            quantity: this.isIndividualLink ? this.quantity : 1
          }
        );
        this.$emit('created', result);
      } finally {
        this.loading = false;
      }
    }
  }
}
