
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IExtendedDataTableHeader } from '@/models/core';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import { StringValidationUtil } from '@/utils/StringValidationUtil';
import OsSearchField from '@/components/core/OsSearchField.vue';
@Component({
  components: { OsSearchField, OsSheet, OsToolbar }
})
export default class OsDatatable extends Vue {
  @Prop({ required: false, default: () => [] })
  extraButtons!: any[];

  @Prop({ required: false, default: '' })
  title!: string;

  @Prop({ required: true, default: [] })
  value!: any[];

  @Prop({ required: true, default: [] })
  headers!: IExtendedDataTableHeader[];

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: 'grey lighten-3' })
  headerBackgroundColor!: string;

  @Prop({ default: true })
  createEnabled!: boolean;

  @Prop({ default: 'mdi-plus' })
  createIcon!: string;

  @Prop({ default: true })
  createVisible!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: true })
  selectableRecords!: boolean;

  @Prop({ default: () => [10, 25, 50, -1] })
  itemsPerPageOptions!: number[];

  @Prop()
  createLabel!: string;
  @Prop()
  searchBarLabel!: string;
  @Prop()
  loadingDataLabel!: string;

  @Prop({ default: true })
  useMinHeight!: boolean;

  @Prop({ default: false })
  showSearchBar!: boolean;

  @Prop({ default: undefined })
  itemClass!: (item: any) => string;

  search = '';
  itemsPerPage = 10;
  currentVisibleItems: any[] = [];

  get createLabelInternal(): string {
    return !StringValidationUtil.isNullOrWhitespace(this.createLabel)
      ? this.createLabel
      : (this as any).$tc('control.entity_data_table.create_button');
  }
  get searchBarLabelInternal(): string {
    return !StringValidationUtil.isNullOrWhitespace(this.searchBarLabel)
      ? this.searchBarLabel
      : (this as any).$tc('control.entity_data_table.search_bar_placeholder');
  }
  get loadingDataLabelInternal(): string {
    return !StringValidationUtil.isNullOrWhitespace(this.loadingDataLabel)
      ? this.loadingDataLabel
      : (this as any).$tc('control.entity_data_table.loading_data_label');
  }

  get calculatedMinHeight() {
    if (!this.useMinHeight) {
      return undefined;
    }

    const minHeightInPx = '530px';

    if (this.value && this.value.length < 10) {
      return minHeightInPx;
    }

    if (
      this.itemsPerPage === 10 &&
      this.currentVisibleItems &&
      this.currentVisibleItems.length < 10
    ) {
      return minHeightInPx;
    }

    return undefined;
  }

  getValue(h: IExtendedDataTableHeader, v: any, item: any) {
    if (
      h &&
      h.formatter &&
      Object.prototype.hasOwnProperty.call(h, 'formatter')
    ) {
      return h.formatter(v, item);
    }

    return v;
  }

  getIsVisible(h: IExtendedDataTableHeader, v: any, item: any) {
    if (h && h.visible && Object.prototype.hasOwnProperty.call(h, 'visible')) {
      return h.visible(item);
    }

    return true;
  }

  getIcon(h: IExtendedDataTableHeader, v: any) {
    if (h && h.icon && typeof h.icon === 'function') {
      return h.icon(v);
    }

    return h.icon;
  }
}
