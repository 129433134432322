import i18n from '@/plugins/vuei18n';
import moment from 'moment';

export class FormatterUtil {
  static boolToHumanStringFormatter(v: boolean): string {
    return v
      ? i18n.tc('global.formatter.bool_to_human_string.true')
      : i18n.tc('global.formatter.bool_to_human_string.false');
  }

  static dateTimeFormatted(v: string, f = 'DD.MM.YY'): string {
    return moment(v).format(f);
  }
}
