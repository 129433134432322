
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { ICustomer, ICustomerSetting } from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsLogo from '@/components/core/OsLogo.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
@Component({
  components: {
    OsAlertBox,
    OsToolbar,
    OsLogo,
    OsSheetContent,
    OsEntityForm,
    OsEntityToolbar
  }
})
export default class OsCustomerForm extends Vue {
  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;

  @PropSync('value', { required: true })
  internalValue!: ICustomer;

  @PropSync('loading', { default: false })
  internalLoading!: boolean;

  @Prop({ default: false, required: true })
  setting!: ICustomerSetting;

  @Prop({ default: false, required: false })
  readonly!: boolean;

  @Prop({ default: 'Company profile' })
  title!: string;

  @Prop({ default: 'Name' })
  textName!: string;

  @Prop({ default: 'Branch' })
  textBranch!: string;

  @Prop({ default: 'Primary contact name' })
  textPrimaryContact!: string;

  @Prop({ default: 'Primary contact e-mail' })
  textPrimaryContactEmailAddress!: string;

  @Prop({ default: 'Report cover information' })
  textReportCoverFieldsTitle!: string;

  @Prop({ default: 'Street' })
  textStreet!: string;

  @Prop({ default: 'City' })
  textCity!: string;

  @Prop({ default: 'Telephone' })
  textTelephone!: string;

  @Prop({ default: 'E-Mail' })
  textEmail!: string;

  @Prop({ default: 'Website' })
  textWebsite!: string;

  customerFormCustomerInfoIsValid = false;
  customerFormReportCoverInfoIsValid = false;

  get customerInfoFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: this.textName,
        propName: 'name',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textBranch,
        propName: 'branch',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textPrimaryContact,
        propName: 'primarycontactname',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textPrimaryContactEmailAddress,
        propName: 'primarycontactemailaddress',
        smCols: 12,
        mdCols: 4,
        required: true
      }
    ];
  }
  get customerReportCoverFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.text,
        label: this.textStreet,
        propName: 'street',
        smCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textCity,
        propName: 'city',
        smCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textTelephone,
        propName: 'telephone',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textEmail,
        propName: 'emailaddress',
        smCols: 12,
        mdCols: 4,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textWebsite,
        propName: 'website',
        smCols: 12,
        mdCols: 4,
        required: true
      }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('onSave');
  }

  onUndo() {
    this.$emit('undoClicked');
  }

  @Watch('internalValue', { deep: true })
  onInternalValueChanged() {
    this.internalAppDirtyUtil.update(this.internalValue);
  }
}
