
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsAppusersOverview from '@/components/shared/appusers/OsAppusersOverview.vue';
import userModule from '@/store/UserModule';
@Component({
  components: { OsAppusersOverview }
})
export default class MyCompanyAppusersIndex extends Vue {
  @Prop({ default: '/mycompany/appusers/create' })
  createFormUrl!: string;

  @Prop({ default: '/mycompany/appusers/' })
  openExistingFormUrl!: string;

  customerId!: string;

  created() {
    this.customerId = userModule.state.customer!.id;
  }

  openExisting(selected: any) {
    this.$router.push(this.openExistingFormUrl + selected.id);
  }

  openCreateForm() {
    this.$router.push(this.createFormUrl);
  }
}
