import { AuthenticationApi } from '@/apis/AuthenticationApi';
import { CustomersApi } from '@/apis/CustomersApi';
import { SurveysApi } from '@/apis/SurveysApi';
import { AppuserApi } from '@/apis/AppuserApi';
import { MetadataApi } from '@/apis/MetadataApi';
import { ReportsApi } from '@/apis/ReportsApi';
import { ParticipantsApi } from '@/apis/ParticipantsApi';
import { RegistrationApi } from '@/apis/RegistrationApi';
import { TargetprofilesApi } from '@/apis/TargetprofilesApi';

const surveysApi: SurveysApi = new SurveysApi();
const customersApi: CustomersApi = new CustomersApi();
const authenticationApi: AuthenticationApi = new AuthenticationApi();
const reportsApi: ReportsApi = new ReportsApi();
const appuserApi: AppuserApi = new AppuserApi();
const metadataApi: MetadataApi = new MetadataApi();
const participantsApi: ParticipantsApi = new ParticipantsApi();
const registrationApi: RegistrationApi = new RegistrationApi();
const targetprofilesApi: TargetprofilesApi = new TargetprofilesApi();

export {
  authenticationApi,
  customersApi,
  surveysApi,
  reportsApi,
  appuserApi,
  metadataApi,
  participantsApi,
  registrationApi,
  targetprofilesApi
};
