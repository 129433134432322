
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import OsSelectCampaignDialog from '@/components/shared/campaigns/OsSelectCampaignDialog.vue';
import { ICampaign } from '@/models/application';
import { customersApi } from '@/apis';
@Component({
  components: {
    OsSelectCampaignDialog,
    OsDatatable
  }
})
export default class OsAppUsersCampaigns extends Vue {
  @Prop({ required: true })
  appuserId!: string;

  @Prop({ required: true })
  customerId!: string;

  @Prop({ default: 'Assigned campaigns' })
  textHeader!: string;

  @Prop({ default: 'Title' })
  textTitle!: string;

  @Prop({ default: 'Assign' })
  textAssign!: string;

  @Prop({ default: 'Remove Campaign' })
  campaignRemoveTitle!: string;

  @Prop({ default: 'Would you like to unassign the assigned campaign?' })
  campaignRemoveText!: string;

  @Prop({ default: 'Campaign successfully removed.' })
  campaignRemovedText!: string;

  @Prop({ default: 'Campaign is already assigned.' })
  campaignAlreadyAssignedText!: string;

  @Prop({ default: 'Campaign successfully added.' })
  campaignAddedSuccessText!: string;

  @Prop({ default: 'Assign Campaign' })
  assignCampaignDialogTitle!: string;

  @Prop({ default: 'Assign Campaign' })
  assignCampaignDialogAcceptButtonText!: string;

  @Prop({ default: 'Campaign to assign' })
  assignCampaignLookupLabel!: string;

  assignDialogVisible = false;
  assignDialogLoading = false;
  selectedCampaignId: string | null = null;

  get header(): IExtendedDataTableHeader[] {
    return [
      {
        text: this.textTitle,
        value: 'title'
      },
      {
        text: '',
        value: 'id',
        icon: 'mdi-link-off',
        width: '15px',
        sortable: false,
        action: async (l: ICampaign) => {
          if (
            await this.$global.dialogs.confirm(
              this.campaignRemoveTitle,
              this.campaignRemoveText
            )
          ) {
            await this.unassignCampaign(l.id);
          }
        },
        align: 'center'
      }
    ];
  }

  campaigns: ICampaign[] = [];

  async mounted() {
    await this.loadAssignedCampaigns();
  }

  async loadAssignedCampaigns() {
    this.campaigns = await customersApi.getAppuserCampaigns(
      this.customerId,
      this.appuserId
    );
  }

  async onCampaignAssignSaved() {
    if (!this.selectedCampaignId) {
      return;
    }

    try {
      this.assignDialogLoading = true;

      await this.assignCampaign(this.selectedCampaignId);

      this.selectedCampaignId = null;
      this.assignDialogVisible = false;
    } finally {
      this.assignDialogLoading = false;
    }
  }

  async unassignCampaign(campaignId: string): Promise<boolean> {
    const result = await customersApi.removeAppuserFromCampaign(
      this.customerId,
      campaignId,
      this.appuserId
    );

    if (result) {
      this.$global.dialogs.toast(this.campaignRemovedText, {
        color: 'success',
        icon: 'mdi-link-off'
      });
      await this.loadAssignedCampaigns();
    }

    return result;
  }

  async assignCampaign(campaignId: string) {
    if (this.campaigns.find((c) => c.id == campaignId)) {
      this.$global.dialogs.toast(this.campaignAlreadyAssignedText, {
        color: 'error',
        icon: 'mdi-content-duplicate'
      });
      return;
    }

    const result = await customersApi.addAppuserToCampaign(
      this.customerId,
      campaignId,
      this.appuserId
    );

    if (result) {
      this.$global.dialogs.toast(this.campaignAddedSuccessText, {
        color: 'success',
        icon: 'mdi-link'
      });
      await this.loadAssignedCampaigns();
    }
  }
}
