
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
@Component({
  components: { OsButton }
})
export default class OsCloseButton extends Vue {
  @Prop()
  to!: string;
}
