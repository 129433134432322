
import { Component, Vue } from 'vue-property-decorator';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsCard from '@/components/core/card/OsCard.vue';
import OsCardText from '@/components/core/card/OsCardText.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsRegistrationForm from '@/components/shared/registration/OsRegistrationForm.vue';
import { IRegisterRequest } from '@/models/authentication/IRegisterRequest';
import { registrationApi } from '@/apis';
import { RegisterResponseType } from '@/models/authentication/RegisterResponseType';
import { LanguageUtil } from '@/utils/LanguageUtil';

@Component({
  components: {
    OsRegistrationForm,
    OsSheet,
    OsAlertBox,
    OsCardText,
    OsCard,
    OsButton,
    OsInputTextField
  }
})
export default class RegisterIndex extends Vue {
  loading = false;
  info: string | null = '';

  mounted() {
    const lang = this.$route.query.lang as string;

    if (lang && ['en', 'de', 'da'].includes(lang)) {
      LanguageUtil.setLanguageAsync(lang);
    }
  }

  async onSave(request: IRegisterRequest) {
    this.info = null;
    this.loading = true;

    request.userEmailaddress = request.userEmailaddress.trim();
    request.languagecode = this.$i18n.locale;

    try {
      const response = await registrationApi.registerTrialAccount(request);

      if (response.type === RegisterResponseType.Success) {
        await this.$router.push('/register/finished');
        return;
      }

      switch (response.type) {
        case RegisterResponseType.EMailAlreadyInUse:
          this.info = this.$tc(
            'page.register.notification.email_already_in_use'
          );
          break;
      }
    } catch {
      this.info = this.$tc('page.register.notification.error_occured');
    } finally {
      this.loading = false;
    }
  }
}
