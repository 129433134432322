import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', (value: any, format = 'DD. MMM. YYYY') => {
  if (value) {
    if (format !== 'human') {
      return moment(String(value)).format(format);
    }

    const target = moment(String(value));

    if (target.isSame(new Date(), 'day')) {
      return target.format('hh:mm');
    }

    const yesterday = moment().subtract(1, 'day');

    if (target.isSame(yesterday, 'day')) {
      return 'yesterday';
    }

    return moment(String(value)).format('MM/DD/YYYY');
  }
});
