import { RouteConfig } from 'vue-router';
import { Survey } from '@/pages';
import {
  SurveyIndex,
  SurveyIntro,
  SurveyQuestions,
  SurveyFinished
} from '@/pages/survey/index';

const routeConfig: RouteConfig = {
  path: '/survey',
  component: Survey,
  meta: {
    anonymousAllowed: true
  },
  children: [
    {
      path: '',
      component: SurveyIndex,
      meta: {
        anonymousAllowed: true
      }
    },
    {
      path: 'intro',
      component: SurveyIntro,
      meta: {
        anonymousAllowed: true
      }
    },
    {
      path: 'questions',
      component: SurveyQuestions,
      meta: {
        anonymousAllowed: true
      }
    },
    {
      path: 'finished',
      component: SurveyFinished,
      meta: {
        anonymousAllowed: true
      }
    }
  ]
};
export default routeConfig;
