
import { Component, Vue } from 'vue-property-decorator';
import OsCampaignForm from '@/components/shared/campaigns/OsCampaignForm.vue';
import { ICampaign, Recipientgroup } from '@/models/application';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { GuidUtil } from '@/utils/GuidUtil';
import { customersApi } from '@/apis';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
import userModule from '@/store/UserModule';

@Component({
  components: {
    OsSubMenuPage,
    OsSheetContent,
    OsSheet,
    OsCampaignForm
  }
})
export default class CampaignCreate extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  campaign: ICampaign = {
    id: GuidUtil.empty(),
    customerId: GuidUtil.empty(),
    questionnaireId: null,
    title: '',
    recipientgroup: Recipientgroup.All
  };

  loading = false;
  customerId!: string;

  created() {
    this.customerId = userModule.state.customer!.id;
  }

  async onSave() {
    this.loading = true;
    try {
      this.campaign.customerId = this.customerId;
      const result = await customersApi.createCampaign(
        this.customerId,
        this.campaign
      );
      this.appDirtyUtil.init(this.campaign);
      await this.$router.push(`/campaigns/` + result.id);
    } finally {
      this.loading = false;
    }
  }
}
