import { BaseApi } from '@/apis/BaseApi';
import {
  IAppuser,
  ICampaign,
  ICustomer,
  ICustomerSetting,
  ICustomerSummary,
  ILink,
  ILinkRequest,
  IParticipant,
  IQuestionnaire,
  ISurveyCustomField,
  ISurveyCustomFieldAnswer,
  ITargetProfile
} from '@/models/application';
import { IRecipient } from '@/models/application/IRecipient';
import { IRecipientRequest } from '@/models/application/IRecipientRequest';
import { IGlobalSearchTargetProfile } from '@/models/application/IGlobalSearchTargetProfile';

export class CustomersApi extends BaseApi {
  constructor() {
    super('customers');
  }

  // base
  async getCustomerById(id: string): Promise<ICustomer> {
    return (await this.get<ICustomer>(`/${id}`)).result;
  }

  async getCustomerSummary(id: string): Promise<ICustomerSummary> {
    return (await this.get<ICustomerSummary>(`/${id}/summary`)).result;
  }

  async updateCustomer(customer: ICustomer): Promise<ICustomer> {
    return (await this.put<ICustomer>(``, customer)).result;
  }

  async uploadCustomerLogo(
    customerId: string,
    logo: FormData
  ): Promise<string> {
    const response = await this.post<string>(
      `/${customerId}/logo`,
      logo,
      true,
      false
    );
    this.showResponseSnackbar(
      response.axiosResponse,
      'updated',
      'success',
      'mdi-check'
    );

    return response.result;
  }

  async deleteLogo(customerId: string): Promise<boolean> {
    const response = await this.delete<boolean>(`/${customerId}/logo`);
    return response.result;
  }

  // campaign
  async getCampaigns(customerId: string): Promise<ICampaign[]> {
    return (await this.get<ICampaign[]>(`/${customerId}/campaigns`)).result;
  }

  async getCampaignById(
    customerId: string,
    campaignId: string
  ): Promise<ICampaign> {
    return (await this.get<ICampaign>(`/${customerId}/campaigns/${campaignId}`))
      .result;
  }

  async createCampaign(
    customerId: string,
    campaign: ICampaign
  ): Promise<ICampaign> {
    campaign.id = '00000000-0000-0000-0000-000000000000';
    return (await this.post<ICampaign>(`/${customerId}/campaigns`, campaign))
      .result;
  }

  async updateCampaign(
    customerId: string,
    campaign: ICampaign
  ): Promise<ICampaign> {
    return (await this.put<ICampaign>(`/${customerId}/campaigns`, campaign))
      .result;
  }

  async deleteCampaignById(
    customerId: string,
    campaignId: string
  ): Promise<boolean> {
    return (
      await this.delete<boolean>(`/${customerId}/campaigns/${campaignId}`)
    ).result;
  }

  async getForCampaignByDISCValuesAsync(
    customerId: string,
    campaignId: string,
    d: number,
    i: number,
    s: number,
    c: number
  ): Promise<IParticipant[]> {
    return (
      await this.get<IParticipant[]>(
        `/${customerId}/campaigns/${campaignId}/participants/ordered?dValue=${d}&iValue=${i}&sValue=${s}&cValue=${c}`
      )
    ).result;
  }

  // questionnaire
  async getQuestionnaires(customerId: string): Promise<IQuestionnaire[]> {
    return (await this.get<IQuestionnaire[]>(`/${customerId}/questionnaires`))
      .result;
  }

  // appusers
  async getAppUsers(customerId: string): Promise<IAppuser[]> {
    return (await this.get<IAppuser[]>(`/${customerId}/appusers`)).result;
  }

  async getAppUserById(
    customerId: string,
    appuserId: string
  ): Promise<IAppuser> {
    return (await this.get<IAppuser>(`/${customerId}/appusers/${appuserId}`))
      .result;
  }

  async createAppuser(
    customerId: string,
    appuser: IAppuser
  ): Promise<IAppuser> {
    appuser.id = '00000000-0000-0000-0000-000000000000';
    return (await this.post<IAppuser>(`/${customerId}/appusers`, appuser))
      .result;
  }

  async updateAppuser(
    customerId: string,
    appuser: IAppuser
  ): Promise<IAppuser> {
    return (await this.put<IAppuser>(`/${customerId}/appusers`, appuser))
      .result;
  }

  async deleteAppuserById(
    customerId: string,
    appuserId: string
  ): Promise<boolean> {
    return (await this.delete<boolean>(`/${customerId}/appusers/${appuserId}`))
      .result;
  }

  async appUserIsAdmin(
    customerId: string,
    appuserId: string
  ): Promise<boolean> {
    return (
      await this.get<boolean>(`/${customerId}/appusers/${appuserId}/isadmin`)
    ).result;
  }

  // participants
  async getCampaignParticipants(
    customerId: string,
    campaignId: string
  ): Promise<IParticipant[]> {
    return (
      await this.get<IParticipant[]>(
        `/${customerId}/campaigns/${campaignId}/participants`
      )
    ).result;
  }

  async getParticipantById(
    customerId: string,
    participantId: string
  ): Promise<IParticipant> {
    return (
      await this.get<IParticipant>(
        `/${customerId}/participants/${participantId}`
      )
    ).result;
  }

  async updateParticipant(
    customerId: string,
    participant: IParticipant
  ): Promise<IParticipant> {
    return (
      await this.put<IParticipant>(`/${customerId}/participants`, participant)
    ).result;
  }

  async downloadParticipantTargetProfileReportAsPdf(
    filename: string,
    customerId: string,
    participantId: string,
    languagecode: string,
    textInThirdPerson: boolean
  ): Promise<boolean> {
    return await this.download(
      filename,
      `/${customerId}/participants/${participantId}/reports/actualprofile?languagecode=${languagecode}&textInThirdPerson=${textInThirdPerson}`
    );
  }

  async getParticipantCustomFieldAnswersById(
    customerId: string,
    participantId: string
  ): Promise<ISurveyCustomFieldAnswer[]> {
    return (
      await this.get<ISurveyCustomFieldAnswer[]>(
        `/${customerId}/participants/${participantId}/surveycustomfieldanswers`
      )
    ).result;
  }

  async setParticipantCustomFieldAnswersById(
    customerId: string,
    participantId: string,
    customFieldAnswers: ISurveyCustomFieldAnswer[]
  ): Promise<ISurveyCustomFieldAnswer[]> {
    return (
      await this.put<ISurveyCustomFieldAnswer[]>(
        `/${customerId}/participants/${participantId}/surveycustomfieldanswers`,
        customFieldAnswers
      )
    ).result;
  }

  // links
  async getCampaignLinks(
    customerId: string,
    campaignId: string
  ): Promise<ILink[]> {
    return (
      await this.get<ILink[]>(`/${customerId}/campaigns/${campaignId}/links`)
    ).result;
  }

  async createCampaignLinks(
    customerId: string,
    campaignId: string,
    linkRequest: ILinkRequest
  ): Promise<ILink[]> {
    return (
      await this.post<ILink[]>(
        `/${customerId}/campaigns/${campaignId}/links`,
        linkRequest
      )
    ).result;
  }

  async deleteCampaignLink(
    customerId: string,
    campaignId: string,
    linkId: string
  ): Promise<boolean> {
    return (
      await this.delete<boolean>(
        `/${customerId}/campaigns/${campaignId}/links/${linkId}`
      )
    ).result;
  }

  async deleteUnusedCampaignLinks(
    customerId: string,
    campaignId: string
  ): Promise<boolean> {
    return (
      await this.delete<boolean>(`/${customerId}/campaigns/${campaignId}/links`)
    ).result;
  }

  // recipients
  async getCampaignRecipients(
    customerId: string,
    campaignId: string
  ): Promise<IRecipient[]> {
    return (
      await this.get<IRecipient[]>(
        `/${customerId}/campaigns/${campaignId}/recipients`
      )
    ).result;
  }

  async createCampaignRecipient(
    customerId: string,
    campaignId: string,
    recipientRequest: IRecipientRequest
  ): Promise<IRecipient> {
    return (
      await this.post<IRecipient>(
        `/${customerId}/campaigns/${campaignId}/recipients`,
        recipientRequest
      )
    ).result;
  }

  async deleteCampaignRecipient(
    customerId: string,
    campaignId: string,
    recipientId: string
  ): Promise<boolean> {
    return (
      await this.delete<boolean>(
        `/${customerId}/campaigns/${campaignId}/recipients/${recipientId}`
      )
    ).result;
  }

  async getSettings(customerId: string): Promise<ICustomerSetting> {
    return (await this.get<ICustomerSetting>(`/${customerId}/settings`)).result;
  }

  async setSettings(
    customerId: string,
    settings: ICustomerSetting
  ): Promise<ICustomerSetting> {
    return (
      await this.put<ICustomerSetting>(`/${customerId}/settings`, settings)
    ).result;
  }

  async getTargetProfiles(
    customerId: string,
    campaignId: string
  ): Promise<ITargetProfile[]> {
    return (
      await this.get<ITargetProfile[]>(
        `/${customerId}/campaigns/${campaignId}/targetprofiles`
      )
    ).result;
  }

  async getCustomerTargetProfiles(
    customerId: string
  ): Promise<IGlobalSearchTargetProfile[]> {
    return (
      await this.get<IGlobalSearchTargetProfile[]>(
        `/${customerId}/targetprofiles`
      )
    ).result;
  }
  async getCustomerTargetProfileById(
    customerId: string,
    targetProfileId: string
  ): Promise<ITargetProfile> {
    return (
      await this.get<ITargetProfile>(
        `/${customerId}/targetprofiles/${targetProfileId}`
      )
    ).result;
  }

  async getTargetProfileById(
    customerId: string,
    targetProfileId: string
  ): Promise<ITargetProfile> {
    return (
      await this.get<ITargetProfile>(
        `/${customerId}/targetprofiles/${targetProfileId}`
      )
    ).result;
  }

  async createTargetProfile(
    customerId: string,
    targetProfile: ITargetProfile
  ): Promise<ITargetProfile> {
    return (
      await this.post<ITargetProfile>(
        `/${customerId}/targetprofiles`,
        targetProfile
      )
    ).result;
  }

  async updateTargetProfile(
    customerId: string,
    targetProfile: ITargetProfile
  ): Promise<ITargetProfile> {
    return (
      await this.put<ITargetProfile>(
        `/${customerId}/targetprofiles`,
        targetProfile
      )
    ).result;
  }

  async deleteTargetProfile(
    customerId: string,
    targetProfileId: string
  ): Promise<boolean> {
    return (
      await this.delete<boolean>(
        `/${customerId}/targetprofiles/${targetProfileId}`
      )
    ).result;
  }

  async getCustomFields(customerId: string): Promise<ISurveyCustomField[]> {
    return (
      await this.get<ISurveyCustomField[]>(`/${customerId}/surveycustomfields/`)
    ).result;
  }

  // assigned app users
  async getCampaignAppusers(
    customerId: string,
    campaignId: string
  ): Promise<IAppuser[]> {
    return (
      await this.get<IAppuser[]>(
        `/${customerId}/campaigns/${campaignId}/appusers`
      )
    ).result;
  }

  async getAppuserCampaigns(
    customerId: string,
    appuserId: string
  ): Promise<ICampaign[]> {
    return (
      await this.get<ICampaign[]>(
        `/${customerId}/appusers/${appuserId}/campaigns`
      )
    ).result;
  }

  async addAppuserToCampaign(
    customerId: string,
    campaignId: string,
    appuserId: string
  ): Promise<boolean> {
    return (
      await this.post<boolean>(
        `/${customerId}/campaigns/${campaignId}/appusers/${appuserId}`
      )
    ).result;
  }

  async removeAppuserFromCampaign(
    customerId: string,
    campaignId: string,
    appuserId: string
  ): Promise<boolean> {
    return (
      await this.delete<boolean>(
        `/${customerId}/campaigns/${campaignId}/appusers/${appuserId}`
      )
    ).result;
  }
}
