
import { Component, Vue } from 'vue-property-decorator';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
@Component({
  components: { OsAntesDisc }
})
export default class OsAntesDiscDummy extends Vue {
  d = 0;
  i = 0;
  s = 0;
  c = 0;

  width = 150;
  height = 250;
}
