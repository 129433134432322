
import { Component, Vue } from 'vue-property-decorator';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
import { IMenuItem } from '@/models/navigation';
@Component({
  components: { OsSubMenuPage, OsAppSubMenu }
})
export default class Participants extends Vue {
  get items(): IMenuItem[] {
    return [
      {
        label: this.$tc('page.participants.menu.overview.title'),
        subLabel: this.$tc('page.participants.menu.overview.sub_text'),
        to: `/participants`,
        exact: true
      }
    ];
  }
}
