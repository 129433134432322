
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OsSetPasswordForm from '@/components/shared/password/OsSetPasswordForm.vue';
import { appuserApi } from '@/apis';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
@Component({
  components: { OsInputTextField, OsSetPasswordForm }
})
export default class OsChangePasswordForm extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  loading = false;
  passwordVisible = false;
  currentPassword = '';
  newPassword = '';
  confirmNewPassword = '';

  @Prop({ default: 'Current password' })
  textCurrentPasswordInputLabel!: string;

  mounted() {
    this.initAppDirtyUtil();
  }

  async setPassword() {
    this.loading = true;

    try {
      const result = await appuserApi.changePassword(
        this.$store.state.user.appuser.id,
        {
          currentPassword: this.currentPassword!,
          password: this.newPassword
        }
      );
      if (result) {
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
      }
    } finally {
      this.loading = false;
    }
  }

  initAppDirtyUtil() {
    this.appDirtyUtil.init({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmNewPassword
    });
  }

  @Watch('currentPassword')
  @Watch('newPassword')
  @Watch('confirmNewPassword')
  onInternalValueChanged() {
    this.appDirtyUtil.update({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmNewPassword
    });
  }
}
