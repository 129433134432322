
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsAlertBox extends Vue {
  @Prop({ default: undefined })
  maxWidth!: string;

  @Prop({ default: 'mdi-alert-circle-outline' })
  icon!: string;

  @Prop({ default: '8' })
  elevation!: string;

  @Prop({ default: 'info' })
  type!: string;
}
