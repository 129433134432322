
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
@Component({
  components: { OsSheetContent, OsSheet }
})
export default class OsDashButton extends Vue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: '' })
  subTitle!: string;

  @Prop({ default: '' })
  icon!: string;

  @Prop({ default: undefined })
  to!: string;

  onClicked(e: any) {
    if (this.disabled) {
      return;
    }

    if (this.to) {
      this.$router.push(this.to);
      return;
    }

    this.$emit('click', e);
  }
}
