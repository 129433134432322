import { render, staticRenderFns } from "./Appusers.vue?vue&type=template&id=c9949c64&scoped=true"
import script from "./Appusers.vue?vue&type=script&lang=ts"
export * from "./Appusers.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9949c64",
  null
  
)

export default component.exports