import {
  IBaseApiResponse,
  IExtendedAxiosRequestConfig,
  IHttpPatch
} from '@/models/core';
import axios, { AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import Vue from 'vue';
import { IGlobalFunctions } from '@/types/IGlobalFunctions';

export class BaseApi {
  url: string;

  constructor(controller: string) {
    this.url = `${controller}`;
  }

  async post<Tout>(
    method = '',
    body?: any,
    includeBearerToken = true,
    showSnackbar = true,
    headers: RawAxiosRequestHeaders = {}
  ): Promise<IBaseApiResponse<Tout>> {
    try {
      const response = await axios.post<Tout>(`${this.url}${method}`, body, {
        includeBearerToken,
        numberOfRefreshRetries: 0,
        showSnackbar,
        headers
      } as IExtendedAxiosRequestConfig);

      if (showSnackbar) {
        this.showResponseSnackbar(response, `created`);
      }

      return {
        result: response.data,
        statusCode: response.status,
        header: response.headers,
        axiosResponse: response
      };
    } catch (r: any) {
      if (
        r &&
        r.status === 422 &&
        r.data &&
        r.data.$type &&
        r.data.$type === 'HandledExceptionResponse'
      ) {
        throw Error('Handled expection: ' + r.data.message);
      }

      this.showErrorSnackBar();
      throw r;
    }
  }

  async get<Tout>(
    method = '',
    includeBearerToken = true,
    showSnackbar = true
  ): Promise<IBaseApiResponse<Tout>> {
    const response = await axios.get<Tout>(`${this.url}${method}`, {
      includeBearerToken,
      numberOfRefreshRetries: 0,
      showSnackbar
    } as IExtendedAxiosRequestConfig);

    return {
      result: response.data,
      statusCode: response.status,
      header: response.headers,
      axiosResponse: response
    };
  }

  async download(
    fileName: string,
    method = '',
    body?: any,
    includeBearerToken = true,
    showSnackbar = true
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (body) {
        axios
          .post<BlobPart>(`${this.url}${method}`, body, {
            includeBearerToken: includeBearerToken,
            numberOfRefreshRetries: 0,
            showSnackbar: showSnackbar,
            responseType: 'blob'
          } as IExtendedAxiosRequestConfig)
          .then(async (response) => {
            if (response.status) {
              await this.createBlobObjectAndDownload(response, fileName);
              resolve(true);
            } else {
              this.showErrorSnackBar();
              reject();
            }
          })
          .catch(() => this.showErrorSnackBar());
      } else {
        axios
          .get<BlobPart>(`${this.url}${method}`, {
            includeBearerToken: includeBearerToken,
            numberOfRefreshRetries: 0,
            showSnackbar: showSnackbar,
            responseType: 'blob'
          } as IExtendedAxiosRequestConfig)
          .then(async (response) => {
            if (response.status) {
              await this.createBlobObjectAndDownload(response, fileName);
              resolve(true);
            } else {
              this.showErrorSnackBar();
              reject();
            }
          })
          .catch(() => {
            this.showErrorSnackBar();
            reject();
          });
      }
    });
  }

  createBlobObjectAndDownload(
    response: AxiosResponse,
    fileName: string
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      resolve();
    });
  }

  async put<Tout>(
    method = '',
    body?: any,
    includeBearerToken = true,
    showSnackbar = true
  ): Promise<IBaseApiResponse<Tout>> {
    try {
      const response = await axios.put<Tout>(`${this.url}${method}`, body, {
        includeBearerToken,
        numberOfRefreshRetries: 0,
        showSnackbar
      } as IExtendedAxiosRequestConfig);

      if (showSnackbar) {
        this.showResponseSnackbar(response, `updated`);
      }

      return {
        result: response.data,
        statusCode: response.status,
        header: response.headers,
        axiosResponse: response
      };
    } catch (r: any) {
      if (
        r &&
        r.status === 422 &&
        r.data &&
        r.data.$type &&
        r.data.$type === 'HandledExceptionResponse'
      ) {
        throw Error('Handled expection: ' + r.data.message);
      }

      this.showErrorSnackBar();
      throw r;
    }
  }

  async delete<Tout>(
    method = '',
    includeBearerToken = true,
    showSnackbar = true
  ): Promise<IBaseApiResponse<Tout>> {
    try {
      const response = await axios.delete<Tout>(`${this.url}${method}`, {
        includeBearerToken,
        numberOfRefreshRetries: 0,
        showSnackbar
      } as IExtendedAxiosRequestConfig);

      if (showSnackbar) {
        const deleteResponse = response.data;

        if (deleteResponse) {
          this.showResponseSnackbar(
            response,
            'deleted',
            'success',
            'mdi-check'
          );
        }
      }

      return {
        result: response.data,
        statusCode: response.status,
        header: response.headers,
        axiosResponse: response
      };
    } catch (r: any) {
      if (
        r &&
        r.status === 422 &&
        r.data &&
        r.data.$type &&
        r.data.$type === 'HandledExceptionResponse'
      ) {
        throw Error('Handled expection: ' + r.data.message);
      }

      this.showErrorSnackBar();
      throw r;
    }
  }

  async patch<Tout>(
    id = '',
    patches: IHttpPatch[],
    includeBearerToken = false
  ): Promise<IBaseApiResponse<Tout>> {
    try {
      const response = await axios.patch<Tout>(`${this.url}/${id}`, patches, {
        includeBearerToken,
        numberOfRefreshRetries: 0
      } as IExtendedAxiosRequestConfig);

      return {
        result: response.data,
        statusCode: response.status,
        header: response.headers,
        axiosResponse: response
      };
    } catch (r: any) {
      if (
        r &&
        r.status === 422 &&
        r.data &&
        r.data.$type &&
        r.data.$type === 'HandledExceptionResponse'
      ) {
        throw Error('Handled expection: ' + r.data.message);
      }

      this.showErrorSnackBar();
      throw r;
    }
  }

  showResponseSnackbar(
    response: AxiosResponse,
    m: string,
    color = 'success',
    icon = 'mdi-check'
  ) {
    if (response.status >= 200 && response.status <= 299) {
      const globalFn = Vue.prototype.$global as IGlobalFunctions;
      globalFn.dialogs.toast(m, { color, icon });
    } else if (response.status == undefined || response.status > 399) {
      this.showErrorSnackBar();
    }
  }

  showErrorSnackBar() {
    const globalFn = Vue.prototype.$global as IGlobalFunctions;
    globalFn.dialogs.toast('An error occured', {
      color: 'error',
      icon: 'mdi-alert-circle-outline'
    });
  }
}
