
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';

@Component({
  components: { OsButton, OsInputTextField, OsSheet, OsEntityForm }
})
export default class OsCreateTargetProfileForm extends Vue {
  @Prop({ required: true })
  customerId!: string;

  @Prop({ required: true })
  campaignId!: string;

  @Prop({ default: 'Target Profile name' })
  textTargetProfileName!: string;

  @Prop({ default: 'Blank Target Profile' })
  textEmptyTargetProfile!: string;

  @Prop({ default: 'Create Target Profile' })
  textCreateTargetProfile!: string;

  @Prop({ default: 'Take guided Tour' })
  textOpenGuidedQuestions!: string;

  @Prop({
    default:
      '<span class="font-weight-bold">blank profile</span>, configurable by <br /><span class="font-weight-bold">drag and drop</span>'
  })
  textEmptyTargetProfileSubText!: string;

  @Prop({ default: 'Guided creation' })
  guidedButtonTitle!: string;

  @Prop({
    default:
      '<span class="font-weight-bold">target profile</span>,<br />created by <span class="font-weight-bold">questions</span>'
  })
  guidedButtonSubText!: string;

  selectedIndexType: number | undefined = -1;
  loading = false;

  targetProfileName = '';

  get isGuidedSelected(): boolean {
    return this.selectedIndexType === 1;
  }

  get isValidForm() {
    return (
      (this.selectedIndexType === 0 || this.selectedIndexType === 1) &&
      !!this.targetProfileName
    );
  }

  reset() {
    this.selectedIndexType = -1;
  }

  async onSubmit() {
    if (this.isValidForm) {
      this.loading = true;
      try {
        this.$emit('click:create', {
          name: this.targetProfileName,
          useGuidedTour: this.isGuidedSelected
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
