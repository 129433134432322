
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
import OsQuestionnairePage from '@/layouts/pages/OsQuestionnairePage.vue';
import OsSurveyStepIntroLanguage from '@/components/views/survey/intro/OsSurveyStepIntroLanguage.vue';
import OsSurveyStepIntroInfo from '@/components/views/survey/intro/OsSurveyStepIntroInfo.vue';
import OsSurveyStepIntroData from '@/components/views/survey/intro/OsSurveyStepIntroData.vue';
import { IParticipant, ISurveyCustomField } from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';
import { surveysApi } from '@/apis';
@Component({
  components: {
    OsSurveyStepIntroData,
    OsSurveyStepIntroInfo,
    OsSurveyStepIntroLanguage,
    OsQuestionnairePage,
    OsButton
  }
})
export default class SurveyIntro extends Vue {
  $refs!: {
    stepIntroData: OsSurveyStepIntroData;
  };

  @Prop()
  campaignId!: string;

  @Prop()
  token!: string;

  @Prop()
  customFields!: ISurveyCustomField[];

  loading = false;
  step = 0;
  dataIsValid = false;
  lang = 'en';

  participant: IParticipant = {
    id: GuidUtil.empty(),
    campaignId: '',
    gender: null,
    firstname: '',
    lastname: '',
    emailaddress: '',
    languagecode: '',
    companyname: '',
    dValue: null,
    iValue: null,
    sValue: null,
    cValue: null
  };

  mounted() {
    this.participant.campaignId = this.campaignId;
  }

  get isNextValid() {
    if (this.step === 0) {
      return this.lang;
    }

    if (this.step === 2) {
      return this.dataIsValid;
    }

    return true;
  }

  async createParticipant() {
    const customFieldAnswers =
      this.$refs.stepIntroData.getCustomFieldsAsAnswers(GuidUtil.empty());

    this.participant = await surveysApi.createParticipant(
      this.campaignId,
      this.token,
      { participant: this.participant, customFieldAnswers }
    );
    this.$emit('update:participantId', this.participant.id);
  }

  async onNextStep() {
    if (this.step === 2) {
      this.participant.languagecode = this.lang;
      await this.createParticipant();
      await this.$router.push(`/survey/questions?token=${this.token}`);
      return;
    }

    this.step++;
  }
}
