import { BaseApi } from '@/apis/BaseApi';
import { ITargetProfile } from '@/models/application';

export class TargetprofilesApi extends BaseApi {
  constructor() {
    super('targetprofiles');
  }

  async getByExternalToken(token: string): Promise<ITargetProfile> {
    return (
      await this.get<ITargetProfile>(`/externaltoken/${token}`, false, false)
    ).result;
  }

  async setByExternalToken(
    token: string,
    profile: ITargetProfile
  ): Promise<ITargetProfile> {
    return (
      await this.put<ITargetProfile>(
        `/externaltoken/${token}`,
        profile,
        false,
        false
      )
    ).result;
  }
}
