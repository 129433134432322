import Vue from 'vue';
import VueI18n from 'vue-i18n';

// import en from './lang/en';
// import de from './lang/de';
// import da from './lang/da';
//
// const messages = {
//   en,
//   de,
//   da
// };

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en'
  // messages // set locale messages
});

export default i18n;
