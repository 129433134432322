export enum EntityFormFieldType {
  text = 'text',
  number = 'number',
  email = 'email',
  textarea = 'textarea',
  date = 'date',
  currency = 'currency',
  select = 'select',
  lookup = 'lookup',
  slider = 'slider',
  radio = 'radio',
  checkbox = 'checkbox'
}
