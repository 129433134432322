import { RouteConfig } from 'vue-router';
import { ParticipantsIndex, ParticipantById } from '@/pages/participants/index';
import { Participants } from '@/pages';

const routeConfig: RouteConfig = {
  path: '/participants',
  component: Participants,
  children: [
    {
      path: '',
      component: ParticipantsIndex
    },
    {
      path: ':participantId',
      component: ParticipantById
    },
    {
      path: '',
      redirect: '/'
    }
  ]
};
export default routeConfig;
