import { BaseApi } from '@/apis/BaseApi';
import { IBaseApiResponse } from '@/models/core';
import { IChangePasswordRequest } from '@/models/authentication/IChangePasswordRequest';

export class AppuserApi extends BaseApi {
  constructor() {
    super('appusers');
  }

  async setLanguage(
    appuserId: string,
    languageCode: string
  ): Promise<IBaseApiResponse<boolean>> {
    return await this.put<boolean>(
      `/${appuserId}?languageCode=${languageCode}`,
      null,
      true,
      false
    );
  }

  async changePassword(
    appuserId: string,
    request: IChangePasswordRequest
  ): Promise<boolean> {
    const response = await this.post<boolean>(
      `/${appuserId}/changepassword`,
      request,
      true,
      false
    );

    this.showResponseSnackbar(
      response.axiosResponse as any,
      'Password changed successfully.',
      'success',
      ''
    );

    return response.result;
  }

  async getIsAdmin(appuserId: string): Promise<boolean> {
    return (await this.get<boolean>(`/${appuserId}/roles/isadmin`)).result;
  }
}
