
import { Component, Vue } from 'vue-property-decorator';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import { IMenuItem } from '@/models/navigation';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
@Component({
  components: { OsSubMenuPage, OsAppSubMenu }
})
export default class MyCompany extends Vue {
  get items(): IMenuItem[] {
    return [
      {
        label: this.$tc('page.my_company.menu.my_company.title'),
        subLabel: this.$tc('page.my_company.menu.my_company.sub_text'),
        to: '/mycompany',
        exact: true
      },
      {
        label: this.$tc('page.my_company.menu.settings.title'),
        subLabel: this.$tc('page.my_company.menu.settings.sub_text'),
        to: '/mycompany/settings'
      },
      {
        label: this.$tc('page.my_company.menu.appusers.title'),
        subLabel: this.$tc('page.my_company.menu.appusers.sub_text'),
        to: '/mycompany/appusers'
      }
    ];
  }
}
