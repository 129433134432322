
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsQuestionnairePage from '@/layouts/pages/OsQuestionnairePage.vue';
import OsButton from '@/components/core/OsButton.vue';
import { ISurvey } from '@/models/application/ISurvey';
import { surveysApi } from '@/apis';
import OsSurvey from '@/components/views/survey/question/OsSurvey.vue';
import { ISelectedAnswer } from '@/components/views/survey/question/OsSurveyQuestion.vue';
import { IParticipantAnswer } from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';
import { IQuestion } from '@/models/application/IQuestion';
import OsSurveyTutorial from '@/components/views/survey/question/OsSurveyTutorial.vue';
import OsSurveyWorkplaceSwitch from '@/components/views/survey/question/OsSurveyWorkplaceSwitch.vue';
@Component({
  components: {
    OsSurveyWorkplaceSwitch,
    OsSurveyTutorial,
    OsSurvey,
    OsButton,
    OsQuestionnairePage
  }
})
export default class SurveyQuestions extends Vue {
  @Prop()
  campaignId!: string;

  @Prop()
  participantId!: string;

  @Prop()
  token!: string;

  showTutorial = true;
  showWorkplaceSwitch = false;
  survey: ISurvey | null = null;
  selectedQuestion: IQuestion | null = null;
  selectedQuestionIndex = 0;
  selectedQuestionIsCompleted = false;
  selectedQuestionAnswer: ISelectedAnswer | null = null;
  isLastQuestion = false;
  finishedPercentage = 0;
  answers: IParticipantAnswer[] = [];

  onNextProcessing = false;
  sendAnswersLoading = false;

  get isNextDisabled(): boolean {
    if (this.showTutorial || this.showWorkplaceSwitch) {
      return false;
    }

    return !this.selectedQuestionIsCompleted || this.onNextProcessing;
  }

  get isCombiSurvey(): boolean {
    if (!this.survey?.questions) {
      return false;
    }

    return this.survey.questions.length > 20;
  }

  async mounted() {
    this.answers = [];
    await this.loadSurvey();
  }

  async loadSurvey() {
    this.survey = await surveysApi.getSurveyForCampaignId(
      this.campaignId,
      this.token,
      this.$i18n.locale
    );
  }

  async onNext() {
    this.onNextProcessing = true;

    await this.processNext();

    this.onNextProcessing = false;
  }

  async processNext() {
    if (this.showTutorial) {
      this.showTutorial = false;
      return;
    }

    if (this.showWorkplaceSwitch) {
      this.showWorkplaceSwitch = false;
      return;
    }

    this.answers.push({
      id: GuidUtil.empty(),
      participantId: this.participantId,
      questionId: this.selectedQuestion!.id,
      matchingAnswerOptionId: this.selectedQuestionAnswer!.matchingValue,
      nonMatchingAnswerOptionId: this.selectedQuestionAnswer!.nonMatchingValue,
      createdOn: new Date().toJSON()
    });

    if (!this.isLastQuestion) {
      this.selectedQuestionIndex++;

      if (
        this.isCombiSurvey &&
        this.selectedQuestionIndex === 20 &&
        !this.showWorkplaceSwitch
      ) {
        this.showWorkplaceSwitch = true;
        return;
      }
    } else {
      await this.sendAnswersAndFinishSurvey();
    }
  }

  async sendAnswersAndFinishSurvey() {
    try {
      this.sendAnswersLoading = true;
      const result = await surveysApi.createSurveyResult(
        this.campaignId,
        this.token,
        this.answers
      );

      await this.$router.push(
        `/survey/finished?token=${this.token}&recipientgroup=${result}`
      );
    } catch {
      this.$global.dialogs.toast('An error occured');
    } finally {
      this.sendAnswersLoading = false;
    }
  }
}
