
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsPasswordInformation from '@/components/shared/password/OsPasswordInformation.vue';
@Component({
  components: {
    OsPasswordInformation,
    OsInputTextField,
    OsButton
  }
})
export default class OsSetPasswordForm extends Vue {
  @Prop({ required: true, default: false })
  readonly!: boolean;
  @Prop({ required: true, default: false })
  loading!: boolean;

  @Prop({ default: 'Password' })
  textPasswordInputLabel!: string;
  @Prop({ default: 'Confirm Password' })
  textConfirmPasswordInputLabel!: string;
  @Prop({ default: 'set password' })
  textSetPasswordButton!: string;

  @PropSync('password')
  internalPassword!: string;
  @PropSync('confirmPassword')
  internalConfirmPassword!: string;

  passwordVisible = false;
  confirmPasswordVisible = false;

  passwordsAreValid = false;

  async resetPassword() {
    this.$emit('setPasswordClicked');
  }
}
