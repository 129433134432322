import { AxiosRequestConfig } from 'axios';
import { LoginResponseType } from '@/models/authentication';
import { IExtendedAxiosRequestConfig } from '@/models/core';
import moment from 'moment';
import store from '@/store';
import { JwtUtil } from '@/utils/JwtUtil';

export class AuthenticationUtil {
  isRefreshing = false;
  subscribers: any[] = [];

  refreshTokenExpirationTimeStamp(token: string | null): number | null {
    try {
      if (!token) {
        return null;
      }

      const decodedToken = JwtUtil.decode(token);

      if (decodedToken && decodedToken.payload && decodedToken.payload.exp) {
        return decodedToken.payload.exp * 1000;
      }
    } catch (ex) {
      console.log(ex);
    }

    return null;
  }

  isRefreshTokenExpired(token: string | null): boolean {
    const timeStamp = this.refreshTokenExpirationTimeStamp(token);
    return (
      timeStamp === null ||
      timeStamp < moment().add(15, 'seconds').unix() * 1000
    );
  }

  onRefreshed() {
    this.subscribers.map((cb) => cb());
  }

  subscribeTokenRefresh(cb: any) {
    this.subscribers.push(cb);
  }

  addBearerToken(config: AxiosRequestConfig) {
    if (config?.headers && store.getters['auth/accessToken']) {
      config.headers.Authorization =
        'Bearer ' + store.getters['auth/accessToken'];
    }
  }

  shouldIncludeBearerToken(config: IExtendedAxiosRequestConfig) {
    return config.includeBearerToken;
  }

  refreshTokenAndRetryRequest(
    config: IExtendedAxiosRequestConfig
  ): Promise<IExtendedAxiosRequestConfig> {
    return new Promise(async (resolve) => {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        const loginResponse = await store.dispatch('auth/refresh');
        this.isRefreshing = false;
        if (loginResponse && loginResponse.type === LoginResponseType.Success) {
          this.onRefreshed();
          this.subscribers = [];
          this.addBearerToken(config);
          return resolve(config);
        } else {
          this.subscribers = [];
          await store.dispatch('auth/logout');
          return resolve(config);
        }
      } else {
        return this.subscribeTokenRefresh(() => {
          this.addBearerToken(config);
          resolve(config);
        });
      }
    });
  }
}
