
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
@Component({
  components: { OsSheet }
})
export default class OsSubMenuPage extends Vue {
  @Prop()
  persistentVertical!: boolean;
}
