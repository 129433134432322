
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
import { ITargetProfile } from '@/models/application';
import { reportsApi } from '@/apis';
import OsLanguageSelection from '@/components/app/OsLanguageSelection.vue';
@Component({
  components: { OsLanguageSelection, OsButton }
})
export default class OsTargetProfilePdfDownloadBtn extends Vue {
  @Prop({ default: 'en' })
  lang!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ required: true, default: null })
  targetProfile!: ITargetProfile;

  @Prop({ required: true, default: '' })
  selectedProfile!: string;

  @Prop({ required: true, default: false })
  customerId!: string;

  @Prop({ default: 'Download target profile ...' })
  textDownloadReportButtonTitle!: string;

  @Prop({ default: 'Download target profile' })
  textDownloadLanguageSelectButtonTitle!: string;

  @Prop({ default: 'Select the target profile language' })
  textDownloadLanguageSelectButtonSubTitle!: string;

  loading = false;

  async renderPdf(locale: string) {
    if (this.targetProfile === null) {
      return;
    }

    this.loading = true;
    let filename = `Target_Profile_${this.selectedProfile}.pdf`;

    if (this.selectedProfile) {
      await reportsApi.renderTargetProfile(
        filename,
        this.selectedProfile,
        this.targetProfile.name,
        locale,
        this.targetProfile
      );
    }
    this.loading = false;
  }
}
