import i18n from '@/plugins/vuei18n';
import axios from 'axios';

export class LanguageUtil {
  static loadedLanguages: string[] = [];

  static async setLanguageAsync(lang: string) {
    if (lang === 'debug') {
      i18n.fallbackLocale = '';
      i18n.locale = 'debug';
      return;
    }

    if (!this.loadedLanguages.includes(lang)) {
      const result = await axios.get(
        `${config.translationsBaseUrl}/${lang}.json`
      );
      this.loadedLanguages.push(lang);
      const messages = result.data;

      i18n.setLocaleMessage(lang, messages);
    }

    i18n.fallbackLocale = 'en';
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html')?.setAttribute('lang', lang);

    return Promise.resolve(lang);
  }
}
