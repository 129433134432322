import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from './vuei18n';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    /* eslint-disable */
    t: (key, ...params) => i18n.t(key, params) as string
    /* eslint-enable */
  },
  theme: {
    themes: {
      light: {
        primary: '#4585f5',
        secondary: '#405092',
        accent: '#f26430',
        error: '#d30028',
        info: '#4585f5',
        success: '#4CAF50',
        warning: '#f26430'
      }
    }
  }
});
