
import { Component, Vue } from 'vue-property-decorator';
import OsButton from '@/components/core/OsButton.vue';
@Component({
  components: { OsButton }
})
export default class OsInformDialog extends Vue {
  // resolve: (b: boolean) => void = () => {};
  resolve: ((b: boolean) => void) | null = null;
  visible = false;
  title = '';
  body = '';

  open(title = '', body = ''): Promise<boolean> {
    this.title = title;
    this.body = body;
    this.visible = true;
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  close() {
    this.visible = false;

    if (this.resolve) {
      this.resolve(true);
    }
  }
}
