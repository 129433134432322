
import { Component, Vue } from 'vue-property-decorator';
import { surveysApi } from '@/apis';
import OsQuestionnairePage from '@/layouts/pages/OsQuestionnairePage.vue';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import { LanguageUtil } from '@/utils/LanguageUtil';
import { ISurveyCustomField } from '@/models/application';
@Component({
  components: { OsAlertBox, OsLoadingIndicator, OsQuestionnairePage }
})
export default class Survey extends Vue {
  token = '';
  campaignId = '';
  participantId = '';
  customFields: ISurveyCustomField[] = [];
  loading = true;
  tokenError = false;
  message: string | null = null;

  async mounted() {
    this.token = this.$route.query.token as string;

    if (this.token === '') {
      this.tokenError = true;
      this.message = this.$tc('page.survey.token_validation.missing_token');
      this.loading = false;
      return;
    }

    let response;
    try {
      response = await surveysApi.getSurveyResultByToken(this.token);
      const surveyResult = response.result;
      this.campaignId = surveyResult.campaignId;
      this.participantId = surveyResult.participantId;
      this.customFields = surveyResult.customFields;

      if (surveyResult.languagecode) {
        await LanguageUtil.setLanguageAsync(surveyResult.languagecode);
      }

      if (!surveyResult.isAvailable) {
        if (!this.$route.path.startsWith('/survey/finished')) {
          await this.$router.push(`/survey/finished?token=${this.token}`);
        }
      } else if (surveyResult.participantId) {
        if (!this.$route.path.startsWith('/survey/questions')) {
          await this.$router.push(`/survey/questions?token=${this.token}`);
        }
      } else {
        if (!this.$route.path.startsWith('/survey/intro')) {
          await this.$router.push(`/survey/intro?token=${this.token}`);
        }
      }
    } catch {
      if (response?.statusCode !== 200) {
        this.tokenError = true;
        this.message = this.$t('page.survey.token_validation.invalid_token', {
          token: this.token
        }) as string;
      }
    } finally {
      this.loading = false;
    }
  }
}
