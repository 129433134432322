
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { ISurvey } from '@/models/application/ISurvey';
import OsSurveyQuestion, {
  ISelectedAnswer
} from '@/components/views/survey/question/OsSurveyQuestion.vue';
import { IQuestion } from '@/models/application/IQuestion';
@Component({
  components: { OsSurveyQuestion }
})
export default class OsSurvey extends Vue {
  @Prop({ required: true })
  survey!: ISurvey;

  @Prop({ required: false })
  isLastQuestion!: boolean;

  @Prop({ default: 0 })
  finishedPercentage!: number;

  @Prop()
  selectedQuestion!: IQuestion;

  @PropSync('selectedQuestionIndex', { default: 0 })
  internalSelectedQuestionIndex!: number;

  @PropSync('selectedAnswer')
  internalSelectedAnswer!: ISelectedAnswer;

  @PropSync('selectedQuestionCompleted', { default: false })
  internalSelectedQuestionCompleted!: boolean;

  @Prop({ required: false, default: false })
  readonly!: boolean;

  mounted() {
    this.onInternalSelectedQuestionIndexChanged();
  }

  @Watch('internalSelectedQuestionIndex')
  onInternalSelectedQuestionIndexChanged() {
    this.$emit(
      'update:isLastQuestion',
      this.internalSelectedQuestionIndex + 1 === this.survey.questions.length
    );

    this.$emit(
      'update:finishedPercentage',
      (this.internalSelectedQuestionIndex / this.survey.questions.length) * 100
    );

    this.$emit('update:selectedQuestion', this.internalSelectedQuestion);
  }

  get internalSelectedQuestion() {
    return this.survey.questions.sort((a, b) => {
      return a.sequencenumber - b.sequencenumber;
    })[this.internalSelectedQuestionIndex];
  }
}
