import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { IAppuser, ICustomer } from '@/models/application';
import { appuserApi, customersApi } from '@/apis';

class State {
  customer: ICustomer | null = null;
  appuser: IAppuser | null = null;
}

const mutations = <MutationTree<State>>{
  setAppUser(state, payload: IAppuser) {
    state.appuser = payload;
  },
  setAppUserLanguageCode(state, payload: string) {
    state.appuser!.languagecode = payload;
  },
  setCustomer(state, payload: ICustomer) {
    state.customer = payload;
  },
  setCustomerName(state, payload: string) {
    if (state.customer) {
      state.customer.name = payload;
    }
  }
};

const actions = <ActionTree<State, any>>{
  async fetchLoggedInUserData(
    store,
    payload: { appuserId: string; customerId: string }
  ): Promise<void> {
    if (!payload) {
      return;
    }

    const customer = await customersApi.getCustomerById(payload.customerId);
    this.commit('user/setCustomer', customer);

    const appuser = await customersApi.getAppUserById(
      payload.customerId,
      payload.appuserId
    );
    this.commit('user/setAppUser', appuser);
  },
  async setLanguageCode(store, payload: string): Promise<void> {
    if (!payload) {
      return;
    }

    const success = await appuserApi.setLanguage(
      this.state.user.appuser.id,
      payload
    );
    if (success) {
      this.commit('user/setAppUserLanguageCode', payload);
    }
  },
  async setCustomerName(store, payload: string): Promise<void> {
    this.commit('user/setCustomerName', payload);
  }
};

const getters = <GetterTree<State, any>>{};

const UserModule = {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default UserModule;
