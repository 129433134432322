
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { AppuserState, IAppuser } from '@/models/application';
import { GuidUtil } from '@/utils/GuidUtil';
import OsAppuserForm from '@/components/shared/appusers/OsAppuserForm.vue';
import { customersApi } from '@/apis';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import userModule from '@/store/UserModule';

@Component({
  components: { OsAppuserForm, OsSheetContent, OsSheet }
})
export default class CustomersAppUsersCreate extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  @Prop({ default: '/mycompany/appusers/' })
  afterCreationForwardingUrl!: string;

  appuser: IAppuser = {
    id: GuidUtil.empty(),
    customerId: GuidUtil.empty(),
    emailaddress: '',
    firstname: '',
    lastname: '',
    state: AppuserState.Active,
    languagecode: null
  };

  loading = false;
  customerId!: string;

  created() {
    this.customerId = userModule.state.customer!.id;
  }

  async onSave() {
    this.loading = true;
    try {
      this.appuser.customerId = this.customerId;
      await customersApi.createAppuser(this.customerId, this.appuser);
      this.appDirtyUtil.init(this.appuser);
      await this.$router.push(this.afterCreationForwardingUrl);
    } finally {
      this.loading = false;
    }
  }
}
