
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class OsConfirmDialog extends Vue {
  visible = false;
  resolve: ((b: boolean) => void) | null = null;
  reject: ((b: boolean) => void) | null = null;
  message = '';
  title = '';
  options: any = {
    color: 'primary',
    width: 300,
    height: 200
  };

  open(title: string, message: string, options: any): Promise<boolean> {
    this.visible = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  agree() {
    if (this.resolve) {
      this.resolve(true);
    }

    this.visible = false;
  }

  cancel() {
    if (this.resolve) {
      this.resolve(false);
    }
    this.visible = false;
  }
}
