
import { Component, ModelSync, Prop, Vue, Watch } from 'vue-property-decorator';
import { IEntityFormField } from '@/models/core';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsInputTextArea from '@/components/core/input/OsInputTextArea.vue';
import OsInputDatePicker from '@/components/core/input/OsInputDatePicker.vue';
import OsInputSelect from '@/components/core/input/OsInputSelect.vue';
import OsInputLookup from '@/components/core/input/OsInputLookup.vue';
import OsInputSlider from '@/components/core/input/OsInputSlider.vue';
import OsInputRadioGroup from '@/components/core/input/OsInputRadioGroup.vue';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';

@Component({
  components: {
    OsInputCheckbox,
    OsInputRadioGroup,
    OsInputSlider,
    OsInputLookup,
    OsInputSelect,
    OsInputDatePicker,
    OsInputTextArea,
    OsInputTextField
  }
})
export default class OsEntityForm extends Vue {
  @ModelSync('value', 'changed', { required: true })
  internalValue!: any;

  @Prop({ required: true, default: [] })
  fields!: IEntityFormField[];

  @Prop({ required: false, default: false })
  readonly!: boolean;

  $refs!: {
    form: any;
  };

  internalIsValid = false;

  @Watch('internalIsValid')
  onInternalIsValidChanged() {
    this.$emit('update:isValid', this.internalIsValid);
  }

  reset() {
    this.$refs.form.reset();
  }

  mounted() {
    // if (this.fields.some((f) => f.type === EntityFormFieldType.lookup)) {
    //   if (this.fields.some((f) => f.entityName === 'something')) {
    //     lookupModule.something();
    // }
  }
}
