import store from '@/store';
import jwt from 'jsonwebtoken';

export class JwtUtil {
  static decode(token: string): any {
    return jwt.decode(token, { complete: true }) as any;
  }

  static getDecodedPayload(): {
    AppUserId: string;
    CustomerId: string;
    aud: string;
    exp: number;
    iss: string;
  } | null {
    try {
      if (store.getters['auth/accessToken']) {
        const parsedToken = JwtUtil.decode(store.getters['auth/accessToken']);
        return parsedToken.payload;
      }
      return null;
    } catch (e) {
      return null;
    }
  }
}
