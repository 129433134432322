
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsInputSlider extends Vue {
  @ModelSync('value', 'changed', { required: true, default: '' })
  internalValue!: number;

  @Prop({ required: false, default: false })
  readonly!: string;

  @Prop({ required: true, default: '' })
  label!: string;

  @Prop({ required: false, default: '' })
  type!: string;

  @Prop({ required: false, default: false })
  required!: boolean;

  @Prop({ required: false, default: 0.01 })
  steps!: number;

  get rules(): any {
    const rules = [];

    if (this.required) {
      rules.push((v: string) => !!v || 'Pflichtfeld');
    }

    return rules;
  }

  get formattedValue() {
    return (this.internalValue * 100).toFixed(0);
  }
}
