
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsInputTextArea extends Vue {
  @ModelSync('value', 'changed', { required: true, default: '' })
  internalValue!: string;

  @Prop({ required: false, default: false })
  readonly!: string;

  @Prop({ required: true, default: '' })
  label!: string;

  @Prop({ required: false, default: false })
  required!: boolean;

  get rules(): any {
    const rules = [];

    if (this.required) {
      rules.push((v: any) => !!v || 'Pflichtfeld');
    }

    return rules;
  }
}
