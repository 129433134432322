import { RouteConfig } from 'vue-router';
import Register from '@/pages/Register.vue';
import RegisterIndex from '@/pages/register/Index.vue';
import RegisterFinished from '@/pages/register/finished.vue';

const routeConfig: RouteConfig = {
  path: '/register',
  component: Register,
  meta: {
    anonymousAllowed: true
  },
  children: [
    {
      path: '',
      name: 'RegisterIndex',
      component: RegisterIndex,
      meta: {
        anonymousAllowed: true
      },
      children: []
    },
    {
      path: 'finished',
      name: 'RegisterFinished',
      component: RegisterFinished,
      meta: {
        anonymousAllowed: true
      },
      children: []
    }
  ]
};
export default routeConfig;
