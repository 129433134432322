
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import {
  Gender,
  IParticipant,
  ISurveyCustomField,
  ISurveyCustomFieldAnswer
} from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import OsButton from '@/components/core/OsButton.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsParticipantCustomFieldAnswersForm from '@/components/shared/participants/OsParticipantCustomFieldAnswersForm.vue';
@Component({
  components: {
    OsParticipantCustomFieldAnswersForm,
    OsSheetContent,
    OsButton,
    OsEntityForm,
    OsEntityToolbar
  }
})
export default class OsParticipantForm extends Vue {
  @PropSync('appDirtyUtil', { required: true })
  internalAppDirtyUtil!: AppDirtyUtil;
  @PropSync('value', { required: true })
  internalValue!: IParticipant;
  @PropSync('loading', { default: false })
  internalLoading!: boolean;

  @Prop({ default: 'Participant' })
  textHeader!: string;
  @Prop({ default: 'Male' })
  textGenderOptionMale!: string;
  @Prop({ default: 'Female' })
  textGenderOptionFemale!: string;
  @Prop({ default: 'Firstname' })
  textFirstname!: string;
  @Prop({ default: 'Lastname' })
  textLastname!: string;
  @Prop({ default: 'E-Mail-Address' })
  textEmailAddress!: string;
  @Prop({ default: 'Company' })
  textCompany!: string;

  @PropSync('customFieldAnswers', { required: true, default: [] })
  internalCustomFieldAnswers!: ISurveyCustomFieldAnswer[];

  @PropSync('customFields', { required: true, default: [] })
  internalCustomFields!: ISurveyCustomField[];

  formIsValid = false;
  customFieldsValid = false;

  get fields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.radio,
        required: true,
        label: '',
        propName: 'gender',
        mdCols: 12,
        radioAsRow: true,
        items: [
          {
            key: this.textGenderOptionMale,
            value: Gender.Male
          },
          {
            key: this.textGenderOptionFemale,
            value: Gender.Female
          }
        ]
      },
      {
        type: EntityFormFieldType.text,
        label: this.textFirstname,
        propName: 'firstname',
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textLastname,
        propName: 'lastname',
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.email,
        label: this.textEmailAddress,
        propName: 'emailaddress',
        smCols: 6,
        required: true
      },
      {
        type: EntityFormFieldType.text,
        label: this.textCompany,
        propName: 'companyname',
        smCols: 6,
        required: false
      }
    ];
  }

  get isDirty() {
    return AppDirtyUtil.isDirty;
  }

  onSave() {
    this.$emit('onSave');
  }

  // onDelete() {
  //   this.$emit('onDelete');
  // }

  onUndo() {
    this.$emit('undoClicked');
  }

  async mounted() {
    this.internalAppDirtyUtil.init([
      this.internalValue,
      this.internalCustomFieldAnswers
    ]);
  }

  @Watch('internalValue', { deep: true })
  onInternalValueChanged() {
    this.internalAppDirtyUtil.update([
      this.internalValue,
      this.internalCustomFieldAnswers
    ]);
  }

  @Watch('internalCustomFieldAnswers', { deep: true })
  onCustomFieldAnswersChanged() {
    this.internalAppDirtyUtil.update([
      this.internalValue,
      this.internalCustomFieldAnswers
    ]);
  }
}
