
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
@Component({
  components: { OsAntesDisc }
})
export default class OsAntesDiscEditor extends Vue {
  @Prop({ required: true })
  disabled!: boolean;

  @PropSync('d', { required: true, default: 0 })
  internalD!: number;

  @PropSync('i', { required: true, default: 0 })
  internalI!: number;

  @PropSync('s', { required: true, default: 0 })
  internalS!: number;

  @PropSync('c', { required: true, default: 0 })
  internalC!: number;

  width = 150;
  height = 250;

  onDragStart() {
    this.$emit('onDragStart');
  }
  onDragEnd() {
    this.$emit('onDragEnd');
  }
  onChange() {
    this.$emit('onChange');
  }
}
