import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import { IExtendedAxiosRequestConfig } from '@/models/core';
import { AuthenticationUtil } from '@/utils/AuthenticationUtil';
import router from '@/router';
import { IHandledExceptionResponse } from '@/models/core/IHandledExceptionResponse';
import store from '@/store';
import Vue from 'vue';

axios.defaults.baseURL = config.apis.platform;

const auth = new AuthenticationUtil();

axios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const extendedConfig = config as IExtendedAxiosRequestConfig;

    if (auth.shouldIncludeBearerToken(extendedConfig)) {
      if (auth.isRefreshTokenExpired(store.getters['auth/accessToken'])) {
        if (extendedConfig.numberOfRefreshRetries > 3) {
          throw Error('RefreshTokenLoopDetected');
        }
        extendedConfig.numberOfRefreshRetries++;
        return auth.refreshTokenAndRetryRequest(extendedConfig);
      } else {
        auth.addBearerToken(extendedConfig);
      }
    }

    return extendedConfig;
  },
  async (error: AxiosError) => {
    return error;
  }
);

axios.interceptors.response.use(
  async (config: AxiosResponse) => {
    return config;
  },
  async (error: AxiosError<any, any>) => {
    if (error && error.response && error.response.status === 401) {
      await store.dispatch('auth/logout');
      await router?.push('/');
    }

    if (error && error.response && error.response.status === 403) {
      Vue.prototype.$global.dialogs.toast('Forbidden - missing rights', {
        color: 'error'
      });
    }

    if (
      error &&
      error.response &&
      error.response.status === 422 &&
      error.response.data &&
      error.response.data.$type &&
      error.response.data.$type === 'HandledExceptionResponse'
    ) {
      const handledResponse = error.response.data as IHandledExceptionResponse;
      Vue.prototype.$global.dialogs.businessError(handledResponse);
    }

    return Promise.reject(error.response);
  }
);
