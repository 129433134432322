
import { Component, Vue } from 'vue-property-decorator';
import { ICustomerSetting } from '@/models/application';
import OsCustomerSettingsForm from '@/components/shared/customer-settings/OsCustomerSettingsForm.vue';
import { customersApi } from '@/apis';
import userModule from '@/store/UserModule';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
@Component({
  components: { OsAlertBox, OsCustomerSettingsForm }
})
export default class MyCompanySettings extends Vue {
  customerId!: string;

  settings: ICustomerSetting | null = null;

  async created() {
    this.customerId = userModule.state.customer!.id;
    this.settings = await customersApi.getSettings(this.customerId);
  }
}
