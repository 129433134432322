
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import OsLogoUploader from '@/components/core/OsLogoUploader.vue';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
@Component({
  components: { OsLoadingIndicator, OsLogoUploader }
})
export default class OsLogo extends Vue {
  @PropSync('value', { required: true })
  internalValue!: string;

  @Prop({ default: false })
  disabled!: boolean;

  refreshingImage = false;
  source = '';

  created() {
    this.updateSource(this.internalValue);
  }

  updateSource(logoUrl: string) {
    // todo reload image without cache
    this.source = logoUrl + `?${new Date().getMilliseconds()}`;
  }

  // todo reload image without cache
  async refreshImage(logoUrl: string) {
    this.refreshingImage = true;
    this.$emit('update:value', logoUrl);
    this.updateSource(logoUrl);
    this.refreshingImage = false;
  }

  get showPlaceholder() {
    return !this.internalValue && !this.refreshingImage;
  }
}
