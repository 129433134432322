
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import { customersApi } from '@/apis';
import userModule from '@/store/UserModule';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import OsAppuserForm from '@/components/shared/appusers/OsAppuserForm.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import OsAppUsersCampaigns from '@/components/shared/appusers/OsAppUsersCampaigns.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import { IExtendedAppuser } from '@/models/application/IExtendedAppuser';
import OsAppusersOverview from '@/components/shared/appusers/OsAppusersOverview.vue';
import OsCampaignOverview from '@/components/shared/campaigns/OsCampaignOverview.vue';
@Component({
  components: {
    OsCampaignOverview,
    OsAppusersOverview,
    OsAlertBox,
    OsAppUsersCampaigns,
    OsAppuserForm,
    OsLoadingIndicator,
    OsSheetContent,
    OsSheet
  }
})
export default class MyCompanyAppUsersById extends Vue {
  @Prop({ default: '/mycompany/appusers/create' })
  createFormUrl!: string;

  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();

  appuser: IExtendedAppuser | null = null;

  loading = false;
  customerId!: string;

  created() {
    this.customerId = userModule.state.customer!.id;
  }

  async mounted() {
    const appuserId = this.$route.params.appuserId;

    let appuser = (await customersApi.getAppUserById(
      this.customerId,
      appuserId
    )) as IExtendedAppuser;

    appuser.isAdmin = await customersApi.appUserIsAdmin(
      this.customerId,
      appuser.id
    );

    this.appuser = appuser;
  }

  async onSave() {
    this.loading = true;
    try {
      this.appuser!.customerId = this.customerId;
      this.appuser = (await customersApi.updateAppuser(
        this.customerId,
        this.appuser!
      )) as IExtendedAppuser;

      this.appuser.isAdmin = await customersApi.appUserIsAdmin(
        this.customerId,
        this.appuser.id
      );

      this.appDirtyUtil.init(this.appuser);
    } finally {
      this.loading = false;
    }
  }

  async onDelete() {
    if (
      await this.$global.dialogs.confirm(
        this.$tc('form.appuser.delete_dialog.title'),
        this.$t('form.appuser.delete_dialog.message', {
          email: this.appuser!.emailaddress
        }) as string
      )
    ) {
      const result = await customersApi.deleteAppuserById(
        this.customerId,
        this.appuser!.id
      );
      if (result) {
        await this.$router.push('/mycompany/appusers/');
      }
    }
  }

  undoClicked() {
    this.appuser = this.appDirtyUtil.getOriginalObject();
    this.appDirtyUtil.init(this.appuser);
  }

  async openCreateNewForm() {
    await this.$router.push(this.createFormUrl);
  }
}
