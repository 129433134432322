export class WindowUtil {
  static getBaseUrl(): string {
    let origin = window.location.origin;

    if (origin.endsWith('/')) {
      origin = origin.substring(0, origin.length - 1);
    }

    return origin;
  }
}
