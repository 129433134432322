
import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';

interface SearchResultItem {
  group: string;
  name: string;
}

@Component({
  components: { OsLoadingIndicator, OsSheet }
})
export default class OsGlobalSearch extends Vue {
  search = '';
  loading = false;
  items: SearchResultItem[] = [];

  isTextFieldFocused = false;
  isResultHovered = false;

  get showResults(): boolean {
    return this.isTextFieldFocused || this.isResultHovered;
  }

  @Watch('search')
  onModelChanged() {
    if (this.search == null || this.search.length < 3) {
      return;
    }

    this.loading = true;
    this.loadItemsDebounced();
  }

  loadItemsDebounced = _.debounce(async () => {
    await this.loadItems();
  }, 500);

  loadItems() {
    setTimeout(() => {
      this.items = [
        {
          group: 'Customer',
          name: 'ABC AG'
        },
        {
          group: 'Customer',
          name: 'Another company'
        },
        {
          group: 'Participant',
          name: 'Max Mustermeier'
        },
        {
          group: 'Participant',
          name: 'Max Mustermeier 1'
        },
        {
          group: 'Participant',
          name: 'Max Mustermeier 2'
        }
      ];
      this.loading = false;
    }, 1000);
  }
}
