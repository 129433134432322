
import { Component, Vue } from 'vue-property-decorator';
import CampaignTargetProfilesIndex from '@/pages/campaigns/_id/target-profiles/Index.vue';
import userModule from '@/store/UserModule';
import OsTargetProfileOverview from '@/components/shared/target-profiles/OsTargetProfileOverview.vue';
import { customersApi } from '@/apis';
import { IGlobalSearchTargetProfile } from '@/models/application/IGlobalSearchTargetProfile';
@Component({
  components: { OsTargetProfileOverview, CampaignTargetProfilesIndex }
})
export default class TargetProfilesIndex extends Vue {
  customerId!: string;
  targetProfiles: IGlobalSearchTargetProfile[] = [];

  async created() {
    this.customerId = userModule.state.customer!.id;
    this.targetProfiles = await customersApi.getCustomerTargetProfiles(
      this.customerId
    );
  }

  rowClicked(selected: any) {
    this.$router.push(`/target-profiles/${selected.id}`);
  }
}
