
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class OsSnackBar extends Vue {
  isOpen = false;
  color = '';
  text = '';
  icon = '';

  show(
    text: string,
    opt: {
      color: string;
      icon: string;
    } = {
      color: 'primary',
      icon: 'mdi-alert-circle-outline'
    }
  ) {
    this.color = opt.color;
    this.icon = opt.icon;
    this.text = text;
    this.isOpen = true;
  }
}
