
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsButton extends Vue {
  @Prop({ default: false })
  block!: boolean;

  @Prop({ default: undefined })
  color!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: undefined })
  text!: boolean;

  @Prop({ default: undefined })
  icon!: boolean;

  @Prop({ default: undefined })
  activeClass!: string;

  @Prop({ default: undefined })
  to!: string;

  @Prop({ default: undefined })
  small!: boolean;

  @Prop({ default: undefined })
  large!: boolean;

  @Prop({ default: undefined })
  xLarge!: boolean;

  @Prop({ default: undefined })
  xSmall!: boolean;

  @Prop({ default: undefined })
  exactPath!: boolean;

  @Prop({ default: undefined })
  width!: string;

  @Prop({ default: undefined })
  minWidth!: string;
}
