
import { Component, Vue } from 'vue-property-decorator';
import { IMenuItem } from '@/models/navigation';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsProfileIconPopover from '@/components/app/OsProfileIconPopover.vue';
import OsGlobalSearch from '@/components/app/OsGlobalSearch.vue';
import OsLanguageSelection from '@/components/app/OsLanguageSelection.vue';
@Component({
  components: {
    OsLanguageSelection,
    OsGlobalSearch,
    OsProfileIconPopover,
    OsButton,
    OsInputTextField
  }
})
export default class OsAppBar extends Vue {
  pageScrollThreshold = 20;
  pageIsScrolled = false;

  get height(): string {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return '70px';
    }

    if (this.$vuetify.breakpoint.smAndUp) {
      return '120px';
    }

    return '180px';
  }

  get menuItems(): IMenuItem[] {
    if (this.$global.roles.isAdmin()) {
      return [
        {
          label: this.$tc('top_bar.main_menu.home'),
          to: '/home'
        },
        {
          label: this.$tc('top_bar.main_menu.my_company'),
          to: '/mycompany'
        },
        {
          label: this.$tc('top_bar.main_menu.appusers'),
          to: '/appusers'
        },
        {
          label: this.$tc('top_bar.main_menu.campaigns'),
          to: '/campaigns'
        },
        {
          label: this.$tc('top_bar.main_menu.participants'),
          to: '/participants'
        },
        {
          label: this.$tc('top_bar.main_menu.target_profiles'),
          to: '/target-profiles'
        }
      ];
    }

    if (this.$global.roles.isTrial()) {
      return [
        {
          label: this.$tc('top_bar.main_menu.home'),
          to: '/home'
        },
        {
          label: this.$tc('top_bar.main_menu.my_company'),
          to: '/mycompany'
        },
        {
          label: this.$tc('top_bar.main_menu.appusers'),
          to: '/appusers'
        },
        {
          label: this.$tc('top_bar.main_menu.campaigns'),
          to: '/campaigns'
        },
        {
          label: this.$tc('top_bar.main_menu.participants'),
          to: '/participants'
        },
        {
          label: this.$tc('top_bar.main_menu.target_profiles'),
          to: '/target-profiles'
        }
      ];
    }

    return [
      {
        label: this.$tc('top_bar.main_menu.home'),
        to: '/home'
      },
      {
        label: this.$tc('top_bar.main_menu.campaigns'),
        to: '/campaigns'
      },
      {
        label: this.$tc('top_bar.main_menu.participants'),
        to: '/participants'
      },
      {
        label: this.$tc('top_bar.main_menu.target_profiles'),
        to: '/target-profiles'
      }
    ];
  }

  mounted() {
    window.onscroll = () => {
      this.updateScrollState();
    };
    this.updateScrollState();
  }

  updateScrollState() {
    this.pageIsScrolled =
      document.body.scrollTop > this.pageScrollThreshold ||
      document.documentElement.scrollTop > this.pageScrollThreshold;
  }

  async flagClicked(event: any) {
    await this.$store.dispatch('user/setLanguageCode', event);
  }
}
