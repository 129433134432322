const supportedLanguages = [
  {
    name: 'English',
    code: 'en',
    icon: 'fi-gb'
  },
  {
    name: 'Deutsch',
    code: 'de',
    icon: 'fi-de'
  },
  {
    name: 'Dansk',
    code: 'da',
    icon: 'fi-dk'
  },
  {
    name: 'Español',
    code: 'es',
    icon: 'fi-es'
  },
  {
    name: 'Türkçe',
    code: 'tr',
    icon: 'fi-tr'
  },
  {
    name: 'Français',
    code: 'fr',
    icon: 'fi-fr'
  },
  {
    name: 'Русский',
    code: 'ru',
    icon: 'fi-ru'
  },
  {
    name: '中文',
    code: 'cn',
    icon: 'fi-cn'
  }
];

const visibleLanguages = [
  {
    name: 'English',
    code: 'en',
    icon: 'fi-gb'
  },
  {
    name: 'Deutsch',
    code: 'de',
    icon: 'fi-de'
  },
  {
    name: 'Dansk',
    code: 'da',
    icon: 'fi-dk'
  }
];

export { supportedLanguages, visibleLanguages };
