import Vue from 'vue';
import Vuex from 'vuex';
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import AuthModule from '@/store/AuthModule';
import UserModule from '@/store/UserModule';
import AppModule from '@/store/AppModule';

Vue.use(Vuex);

class State {}

const getters = <GetterTree<State, any>>{};

const mutations = <MutationTree<State>>{};

const actions = <ActionTree<State, any>>{};

export default new Vuex.Store({
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    app: AppModule,
    auth: AuthModule,
    user: UserModule
  }
});
