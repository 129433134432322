import { RouteConfig } from 'vue-router';
import {
  MyCompanyAppUsers,
  MyCompanyIndex,
  MyCompanySettings
} from '@/pages/mycompany/index';
import {
  MyCompanyAppUsersById,
  MyCompanyAppusersCreate,
  MyCompanyAppusersIndex
} from '@/pages/mycompany/index';
import { MyCompany } from '@/pages';

const routeConfig: RouteConfig = {
  path: '/mycompany',
  component: MyCompany,
  children: [
    {
      path: '',
      component: MyCompanyIndex
    },
    {
      path: 'settings',
      component: MyCompanySettings
    },
    {
      path: 'appusers',
      component: MyCompanyAppUsers,
      children: [
        {
          path: '',
          component: MyCompanyAppusersIndex
        },
        {
          path: 'create',
          component: MyCompanyAppusersCreate
        },
        {
          path: ':appuserId',
          component: MyCompanyAppUsersById
        }
      ]
    },
    {
      path: '',
      redirect: '/'
    }
  ]
};
export default routeConfig;
