import { render, staticRenderFns } from "./OsTargetProfilePdfDownloadBtn.vue?vue&type=template&id=9db10358&scoped=true"
import script from "./OsTargetProfilePdfDownloadBtn.vue?vue&type=script&lang=ts"
export * from "./OsTargetProfilePdfDownloadBtn.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9db10358",
  null
  
)

export default component.exports