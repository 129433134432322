
import { Component, Vue } from 'vue-property-decorator';
import OsInputTextField from '@/components/core/input/OsInputTextField.vue';
import OsButton from '@/components/core/OsButton.vue';
import OsCard from '@/components/core/card/OsCard.vue';
import OsCardText from '@/components/core/card/OsCardText.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsRegistrationForm from '@/components/shared/registration/OsRegistrationForm.vue';

@Component({
  components: {
    OsRegistrationForm,
    OsSheet,
    OsAlertBox,
    OsCardText,
    OsCard,
    OsButton,
    OsInputTextField
  }
})
export default class RegisterFinished extends Vue {}
