var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('table',{staticStyle:{"min-width":"300px"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"fill-width"}),_c('th',{staticClass:"body-2",staticStyle:{"min-width":"120px"},domProps:{"innerHTML":_vm._s(_vm.$tc('view.survey_question.matching'))}}),_c('th',{staticClass:"body-2",staticStyle:{"min-width":"120px"},domProps:{"innerHTML":_vm._s(_vm.$tc('view.survey_question.not_matching'))}})])]),_c('tbody',[_c('tr',[(_vm.question.text)?_c('td',{staticClass:"pl-4 pt-4 pb-8 body-1",attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.question.text)+" ")]):_vm._e()]),_vm._l((_vm.sortedAnswerOptions),function(ao){return _c('tr',{key:ao.id},[_c('td',{staticClass:"pl-6 py-3 font-weight-bold"},[_vm._v(_vm._s(ao.text))]),_c('td',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('os-input-checkbox',{staticClass:"ma-0 pa-0",style:({
                'pointer-events': _vm.readonly ? 'none' : 'all',
                opacity: _vm.isCheckedRight(ao) ? 0 : 1
              }),attrs:{"readonly":_vm.readonly,"value":_vm.isCheckedLeft(ao),"off-icon":_vm.$vuetify.icons.values.radioOff,"on-icon":_vm.$vuetify.icons.values.radioOn,"color":"success","bigger-radio-buttons-hover-ratio":true},on:{"click":function($event){return _vm.leftRadioClicked(ao)}}})],1)]),_c('td',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('os-input-checkbox',{staticClass:"ma-0 pa-0",style:({
                'pointer-events': _vm.readonly ? 'none' : 'all',
                opacity: _vm.isCheckedLeft(ao) ? 0 : 1
              }),attrs:{"readonly":_vm.readonly,"value":_vm.isCheckedRight(ao),"off-icon":_vm.$vuetify.icons.values.radioOff,"on-icon":_vm.$vuetify.icons.values.radioOn,"color":"success","bigger-radio-buttons-hover-ratio":true},on:{"click":function($event){return _vm.rightRadioClicked(ao)}}})],1)])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }