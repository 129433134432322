import { BaseApi } from '@/apis/BaseApi';
import { IQuestionnaire } from '@/models/application';

export class MetadataApi extends BaseApi {
  constructor() {
    super('metadata');
  }

  async getQuestionnaires(): Promise<IQuestionnaire[]> {
    return (await this.get<IQuestionnaire[]>('/questionnaires')).result;
  }
}
