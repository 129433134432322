import { RouteConfig } from 'vue-router';
import {
  TargetProfilesIndex,
  TargetProfileById
} from '@/pages/target-profiles/index';
import TargetProfiles from '@/pages/TargetProfiles.vue';

const routeConfig: RouteConfig = {
  path: '/target-profiles',
  component: TargetProfiles,
  children: [
    {
      path: '',
      component: TargetProfilesIndex
    },
    {
      path: ':targetprofileid',
      component: TargetProfileById
    },
    {
      path: '',
      redirect: '/'
    }
  ]
};
export default routeConfig;
