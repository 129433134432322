
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { Gender, IGlobalSearchParticipant } from '@/models/application';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
import OsParticipantPdfDownloadBtn from '@/components/shared/participants/OsParticipantPdfDownloadBtn.vue';
import { FormatterUtil } from '@/utils/FormatterUtil';
@Component({
  components: { OsParticipantPdfDownloadBtn, OsDatatable, OsAntesDisc }
})
export default class OsParticipantGlobalOverview extends Vue {
  @PropSync('participants', {
    required: false,
    default: () => []
  })
  internalParticipants!: IGlobalSearchParticipant[];
  @Prop({ required: false })
  loading!: boolean;
  @Prop({ default: true })
  selectableRecords!: boolean;

  @Prop({ default: false })
  disabled!: boolean;
  @Prop({ default: true })
  showCustomerColumn!: boolean;

  @Prop({ default: 'All Participants' })
  textHeader!: string;
  @Prop({ default: 'n/a' })
  textDiscNotAvailable!: string;
  @Prop({ default: '' })
  textLogo!: string;
  @Prop({ default: 'Customer' })
  textCustomerName!: string;
  @Prop({ default: 'Campaign' })
  textCampaignName!: string;
  @Prop({ default: 'Gender' })
  textGender!: string;
  @Prop({ default: 'Male' })
  textGenderOptionMale!: string;
  @Prop({ default: 'Female' })
  textGenderOptionFemale!: string;
  @Prop({ default: 'Firstname' })
  textFirstname!: string;
  @Prop({ default: 'Lastname' })
  textLastname!: string;
  @Prop({ default: 'E-Mail-Address' })
  textEmailAddress!: string;
  @Prop({ default: 'DISC' })
  textDisc!: string;
  @Prop({ default: 'Report' })
  textReport!: string;
  @Prop({ default: 'Created on' })
  textCreatedOn!: string;

  get header(): IExtendedDataTableHeader[] {
    const tableHeaders = [
      {
        text: this.textLogo,
        value: 'customerlogourl',
        width: '50px',
        sortable: false
      },
      {
        text: this.textCampaignName,
        value: 'campaignname',
        action: async (participant: IGlobalSearchParticipant) => {
          await this.goToCampaign(participant);
        }
      },
      {
        text: this.textGender,
        value: 'gender',
        width: '100px',
        formatter: (v) => {
          switch (v) {
            case Gender.Male:
              return this.textGenderOptionMale;
            case Gender.Female:
              return this.textGenderOptionFemale;
          }
          return '';
        }
      },
      {
        text: this.textFirstname,
        value: 'firstname'
      },
      {
        text: this.textLastname,
        value: 'lastname'
      },
      {
        text: this.textEmailAddress,
        value: 'emailaddress'
      },
      {
        text: this.textCreatedOn,
        value: 'createdOn',
        formatter: (v) => FormatterUtil.dateTimeFormatted(v)
      },
      {
        text: this.textDisc,
        width: '70px',
        sortable: false,
        align: 'center',
        value: 'disc'
      },
      {
        text: this.textReport,
        width: '100px',
        sortable: false,
        align: 'center',
        value: 'file'
      }
    ] as IExtendedDataTableHeader[];
    if (this.showCustomerColumn) {
      tableHeaders.splice(1, 0, {
        text: this.textCustomerName,
        value: 'customername',
        action: async () => {
          await this.goToCustomer();
        }
      });
    }
    return tableHeaders;
  }

  async goToCustomer() {
    await this.$router.push(`/mycompany`);
  }
  async goToCampaign(participant: IGlobalSearchParticipant) {
    await this.$router.push(`/campaigns/${participant.campaignId}`);
  }
}
