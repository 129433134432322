import { BaseApi } from '@/apis/BaseApi';
import { ILoginResponse, ILoginRequest } from '@/models/authentication';

export class AuthenticationApi extends BaseApi {
  constructor() {
    super('appuserauthentication');
  }

  async checkAuthentication(): Promise<boolean> {
    return (await this.get<boolean>(`/check-auth`, true, false)).result;
  }

  async refreshToken(refreshToken: string): Promise<ILoginResponse> {
    return (
      await this.post<ILoginResponse>(
        `/refresh-tokens/${refreshToken}/refresh`,
        null,
        false,
        false
      )
    ).result;
  }

  async login(request: ILoginRequest): Promise<ILoginResponse> {
    return (await this.post<ILoginResponse>('/login', request, false, false))
      .result;
  }

  async logout(refreshToken: string): Promise<boolean> {
    return (
      await this.post<boolean>('/logout', refreshToken, true, false, {
        'Content-Type': 'application/json'
      })
    ).result;
  }

  async requestPasswordReset(emailAddress: string): Promise<boolean> {
    const response = await this.post<boolean>(
      '/requestpasswordreset',
      emailAddress,
      false,
      false,
      {
        'Content-Type': 'application/json'
      }
    );

    this.showResponseSnackbar(
      response.axiosResponse as any,
      "Request successful. Please follow the instructions in the e-mail we've sent.",
      'success',
      ''
    );

    return response.result;
  }

  async resetPassword(token: string, password: string): Promise<boolean> {
    const response = await this.post<boolean>(
      `/changepassword/${token}`,
      password,
      false,
      false,
      {
        'Content-Type': 'application/json'
      }
    );

    this.showResponseSnackbar(
      response.axiosResponse as any,
      'Password changed successfully.',
      'success',
      ''
    );

    return response.result;
  }
}
