
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import { IMenuItem } from '@/models/navigation';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
@Component({
  components: { OsSubMenuPage, OsAppSubMenu, OsSheet }
})
export default class MyProfileIndex extends Vue {
  customerId!: string;

  get items(): IMenuItem[] {
    return [
      {
        label: this.$tc('page.my_profile.menu.password.title'),
        subLabel: this.$tc('page.my_profile.menu.password.sub_text'),
        to: `/myprofile/password`
      }
    ];
  }

  async created() {
    this.customerId = this.$route.params.id;
  }
}
