
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsCreateTargetProfileGuidedCreation from '@/components/shared/target-profiles/OsCreateTargetProfileGuidedCreation.vue';
import { DiscType } from '@/models/application/DiscType';
import { ITargetProfile } from '@/models/application';
import { targetprofilesApi } from '@/apis';
@Component({
  components: {
    OsCreateTargetProfileGuidedCreation,
    OsSheetContent,
    OsSheetHeader,
    OsSheet
  }
})
export default class ExternalTargetProfileCreate extends Vue {
  targetProfile: ITargetProfile | null = null;
  token = '';
  loading = false;

  async guidedTourFinished(e: {
    name: string;
    values: { type: DiscType; value: number }[];
  }) {
    await this.createTargetProfile({
      dValue: e.values.find((v) => v.type === DiscType.D)!.value,
      iValue: e.values.find((v) => v.type === DiscType.I)!.value,
      sValue: e.values.find((v) => v.type === DiscType.S)!.value,
      cValue: e.values.find((v) => v.type === DiscType.C)!.value
    });
  }

  async mounted() {
    this.loading = true;
    const token = this.$route.query?.token as string;
    this.token = token;

    if (!token) {
      await this.$router.push(`/external/target-profile?token=${token}`);
      return;
    }

    try {
      this.targetProfile = await targetprofilesApi.getByExternalToken(token);

      if (!this.targetProfile) {
        await this.$router.push(`/external/target-profile?token=${token}`);
        return;
      }
    } catch {
      await this.$router.push(`/external/target-profile?token=${token}`);
    } finally {
      this.loading = false;
    }
  }

  async createTargetProfile(
    discValues: {
      dValue: number;
      iValue: number;
      sValue: number;
      cValue: number;
    } = {
      dValue: 0,
      iValue: 0,
      sValue: 0,
      cValue: 0
    }
  ) {
    try {
      this.loading = true;
      const result = await targetprofilesApi.setByExternalToken(this.token, {
        name: this.targetProfile!.name,
        campaignId: this.targetProfile!.campaignId,
        customerId: '00000000-0000-0000-0000-000000000000',
        id: this.targetProfile!.id,
        ...discValues
      });

      if (result) {
        await this.$router.push(`/external/target-profile/finished`);
        return;
      }
      await this.$router.push(`/external/target-profile?token=${this.token}`);
    } finally {
      this.loading = false;
    }
  }
}
