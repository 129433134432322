
import { Component, Vue } from 'vue-property-decorator';
import OsGlobalDialogs from '@/components/app/OsGlobalDialogs.vue';
import OsLanguageSelection from '@/components/app/OsLanguageSelection.vue';
import { LanguageUtil } from '@/utils/LanguageUtil';
@Component({
  components: { OsLanguageSelection, OsGlobalDialogs }
})
export default class ExternalLayout extends Vue {
  async flagClicked(event: any) {
    await LanguageUtil.setLanguageAsync(event);
  }
}
