
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import OsPasswordInformationItem from '@/components/shared/password/OsPasswordInformationItem.vue';

@Component({
  components: { OsPasswordInformationItem }
})
export default class OsPasswordInformation extends Vue {
  @Prop({
    default: ''
  })
  passwordValue!: string;
  @Prop({
    default: ''
  })
  confirmPasswordValue!: string;
  @PropSync('passwordsAreValid', {
    default: false
  })
  internalPasswordsAreValid!: boolean;

  @Prop({ default: '8 characters' })
  textPasswordLength!: string;
  @Prop({ default: 'numbers' })
  textPasswordContainsNumber!: string;
  @Prop({ default: 'special characters' })
  textPasswordContainsSpecialCharacter!: string;
  @Prop({ default: 'upper- and lowercase characters' })
  textPasswordContainsUppercaseAndLowercaseCharacters!: string;
  @Prop({ default: 'matching passwords' })
  textPasswordsMatching!: string;

  passwordLength = false;
  passwordContainsNumber = false;
  passwordContainsSpecialCharacter = false;
  passwordContainsUppercaseAndLowercaseCharacters = false;
  passwordsAreEqual = false;

  get checkPasswordLength(): boolean {
    this.passwordLength = this.passwordValue.length >= 8;
    this.checkPasswordsAreValid();
    return this.passwordLength;
  }

  get checkPasswordContainsNumber(): boolean {
    this.passwordContainsNumber = /\d/.test(this.passwordValue);
    this.checkPasswordsAreValid();
    return this.passwordContainsNumber;
  }

  get checkPasswordContainsSpecialCharacter(): boolean {
    this.passwordContainsSpecialCharacter =
      //eslint-disable-next-line
      /[-!$%^&*()_+"§€|~=`{}\[\]:\/;<>?,.@#]/.test(this.passwordValue);
    this.checkPasswordsAreValid();
    return this.passwordContainsSpecialCharacter;
  }

  get checkPasswordContainsUppercaseAndLowercaseCharacters(): boolean {
    this.passwordContainsUppercaseAndLowercaseCharacters =
      /[a-z]/.test(this.passwordValue) && /[A-Z]/.test(this.passwordValue);
    this.checkPasswordsAreValid();
    return this.passwordContainsUppercaseAndLowercaseCharacters;
  }

  get checkPasswordsAreEqual(): boolean {
    this.passwordsAreEqual =
      this.passwordValue === this.confirmPasswordValue &&
      !!this.passwordValue &&
      this.passwordValue.length > 0 &&
      !!this.confirmPasswordValue &&
      this.confirmPasswordValue.length > 0;
    this.checkPasswordsAreValid();
    return this.passwordsAreEqual;
  }

  checkPasswordsAreValid() {
    this.internalPasswordsAreValid =
      this.passwordLength &&
      this.passwordContainsNumber &&
      this.passwordContainsSpecialCharacter &&
      this.passwordContainsUppercaseAndLowercaseCharacters &&
      this.passwordsAreEqual;
  }
}
