
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsAppSubMenu from '@/components/app/OsAppSubMenu.vue';
import OsSubMenuPage from '@/layouts/pages/OsSubMenuPage.vue';
import { IMenuItem } from '@/models/navigation';
import OsButton from '@/components/core/OsButton.vue';
import userModule from '@/store/UserModule';
import { ICampaign } from '@/models/application';
@Component({
  components: { OsButton, OsSubMenuPage, OsAppSubMenu, OsSheetContent, OsSheet }
})
export default class CampaignById extends Vue {
  @Prop({ required: false, default: null })
  campaign!: ICampaign | null;
  customerId!: string;
  campaignId = '';

  get items(): IMenuItem[] {
    const items = [
      {
        label: this.$tc('page.campaign.menu.overview.title'),
        subLabel: this.$tc('page.campaign.menu.overview.sub_text'),
        to: `/campaigns`,
        exact: true,
        icon: 'mdi-chevron-left'
      },
      {
        label: this.$tc('page.campaign.menu.campaign.title'),
        subLabel: this.$tc('page.campaign.menu.campaign.sub_text'),
        to: `/campaigns/${this.campaignId}`,
        exact: true
      },
      {
        label: this.$tc('page.campaign.menu.participant.title'),
        subLabel: this.$tc('page.campaign.menu.participant.sub_text'),
        to: `/campaigns/${this.campaignId}/participants`
      },
      {
        label: this.$tc('page.campaign.menu.target_profile.title'),
        subLabel: this.$tc('page.campaign.menu.target_profile.sub_text'),
        to: `/campaigns/${this.campaignId}/target-profiles`
      }
    ];

    if (this.$global.roles.isAdmin()) {
      items.push({
        label: this.$tc('page.campaign.menu.assigned_appusers.title'),
        subLabel: this.$tc('page.campaign.menu.assigned_appusers.sub_text'),
        to: `/campaigns/${this.campaignId}/appusers`
      });
    }

    return items;
  }

  created() {
    this.customerId = userModule.state.customer!.id;
    this.campaignId = this.$route.params.campaignid;
  }
}
