
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import { supportedLanguages, visibleLanguages } from '@/lang';

@Component({
  components: { OsSheet }
})
export default class OsLanguageSelection extends Vue {
  @PropSync('lang', { required: true })
  internalLang!: string;

  @Prop({ default: 'icons' })
  type!: string;

  @Prop({ default: false })
  isPickerOnly!: boolean;

  @Prop({ default: false })
  showAllSupportedLanguages!: boolean;

  selectedLocale = '';
  get selectableLocales(): {
    label: string;
    lang: string;
    icon: string;
  }[] {
    let languages = [];

    if (this.showAllSupportedLanguages) {
      languages = supportedLanguages.map((l) => {
        return { label: l.name, lang: l.code, icon: l.icon };
      });
    } else {
      languages = visibleLanguages.map((l) => {
        return { label: l.name, lang: l.code, icon: l.icon };
      });
    }

    if (this.$store.state.app.langDebug) {
      languages.push({
        label: 'DEBUG',
        lang: 'debug',
        icon: 'fi-eu'
      });
    }

    return languages;
  }

  mounted() {
    if (this.isPickerOnly) {
      return;
    }

    this.selectedLocale = this.internalLang;
  }
}
