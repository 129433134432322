
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IQuestion } from '@/models/application/IQuestion';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';
import { IAnswerOption } from '@/models/application/IAnswerOption';

export interface ISelectedAnswer {
  matchingValue: string;
  nonMatchingValue: string;
}

@Component({
  components: { OsInputCheckbox }
})
export default class OsSurveyQuestion extends Vue {
  @Prop({ required: true })
  question!: IQuestion;

  @Prop()
  isCompleted!: boolean;

  @Prop()
  selectedAnswer!: ISelectedAnswer;

  @Prop({ default: false })
  readonly!: boolean;

  matchingValue: string | null = null;
  nonMatchingValue: string | null = null;

  get sortedAnswerOptions(): IAnswerOption[] {
    return this.question.answeroptions.sort(
      (a, b) => a.sequencenumber - b.sequencenumber
    );
  }

  mounted() {
    if (this.selectedAnswer) {
      if (this.selectedAnswer.matchingValue) {
        this.matchingValue = this.selectedAnswer.matchingValue;
      }
      if (this.selectedAnswer.nonMatchingValue) {
        this.nonMatchingValue = this.selectedAnswer.nonMatchingValue;
      }
    }
  }

  @Watch('question')
  onQuestionChanged() {
    this.matchingValue = null;
    this.nonMatchingValue = null;
    this.updateEmittedValues();
  }

  isCheckedLeft(ao: IAnswerOption) {
    return ao.id === this.matchingValue;
  }

  isCheckedRight(ao: IAnswerOption) {
    return ao.id === this.nonMatchingValue;
  }

  leftRadioClicked(ao: IAnswerOption) {
    if (this.readonly) {
      return;
    }

    if (this.nonMatchingValue === ao.id) {
      this.nonMatchingValue = null;
    }

    if (this.matchingValue === ao.id) {
      this.matchingValue = null;
    } else {
      this.matchingValue = ao.id;
    }

    this.updateEmittedValues();
  }

  rightRadioClicked(ao: IAnswerOption) {
    if (this.readonly) {
      return;
    }

    if (this.matchingValue === ao.id) {
      this.matchingValue = null;
    }

    if (this.nonMatchingValue === ao.id) {
      this.nonMatchingValue = null;
    } else {
      this.nonMatchingValue = ao.id;
    }

    this.updateEmittedValues();
  }

  updateEmittedValues() {
    const isCompleted =
      this.matchingValue !== null && this.nonMatchingValue !== null;

    this.$emit('update:isCompleted', isCompleted);

    if (isCompleted) {
      this.$emit('update:selectedAnswer', {
        matchingValue: this.matchingValue,
        nonMatchingValue: this.nonMatchingValue
      } as ISelectedAnswer);
    }
  }
}
