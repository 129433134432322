import { BaseApi } from '@/apis/BaseApi';
import { IReportContent } from '@/models/application/IReportContent';
import { ITargetProfile } from '@/models/application';

export class ReportsApi extends BaseApi {
  constructor() {
    super('reports');
  }

  async renderTargetProfile(
    filename: string,
    targetDiscType: string,
    jobName: string,
    languagecode: string,
    targetProfile: ITargetProfile | null = null
  ): Promise<boolean> {
    return await this.download(filename, `/target-profile/render`, {
      targetDiscType,
      jobName,
      languagecode,
      targetProfile
    });
  }

  async getTargetProfileContent(
    targetDiscType: string,
    languagecode: string
  ): Promise<IReportContent> {
    return (
      await this.get<IReportContent>(
        `/target-profile/content?targetDiscType=${targetDiscType}&languagecode=${languagecode}`
      )
    ).result;
  }
}
