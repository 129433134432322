
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsSurveyQuestion from '@/components/views/survey/question/OsSurveyQuestion.vue';
import OsAlertBox from '@/components/core/OsAlertBox.vue';
import { IQuestion } from '@/models/application/IQuestion';
import { DiscType } from '@/models/application/DiscType';

@Component({
  components: { OsAlertBox, OsSurveyQuestion }
})
export default class OsSurveyTutorial extends Vue {
  @Prop()
  isCombiSurvey!: string;
  get demoQuestion(): IQuestion {
    return {
      id: '1',
      text: '',
      sequencenumber: 0,
      questionnaireId: '0',
      answeroptions: [
        {
          id: '1',
          sequencenumber: 0,
          text: this.$tc('page.survey.questionnaire.tutorial.example.option_d'),
          questionId: '1',
          discType: DiscType.D
        },
        {
          id: '2',
          sequencenumber: 1,
          text: this.$tc('page.survey.questionnaire.tutorial.example.option_i'),
          questionId: '2',
          discType: DiscType.I
        },
        {
          id: '3',
          sequencenumber: 2,
          text: this.$tc('page.survey.questionnaire.tutorial.example.option_s'),
          questionId: '3',
          discType: DiscType.S
        },
        {
          id: '4',
          sequencenumber: 3,
          text: this.$tc('page.survey.questionnaire.tutorial.example.option_c'),
          questionId: '4',
          discType: DiscType.C
        }
      ]
    };
  }
}
