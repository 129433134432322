
import { Component, Vue } from 'vue-property-decorator';
import OsCampaignOverview from '@/components/shared/campaigns/OsCampaignOverview.vue';
import userModule from '@/store/UserModule';
@Component({
  components: {
    OsCampaignOverview
  }
})
export default class CampaignIndex extends Vue {
  customerId!: string;

  created() {
    this.customerId = userModule.state.customer!.id;
  }

  rowClicked(selected: any) {
    this.$router.push(`/campaigns/` + selected.id);
  }

  create() {
    this.$router.push(`/campaigns/create`);
  }
}
