
import { Component, Vue } from 'vue-property-decorator';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';
import OsDashButton from '@/components/core/OsDashButton.vue';
import OsCampaignSummary from '@/components/views/campaigns/OsCampaignSummary.vue';
import OsHeader from '@/components/app/OsHeader.vue';
@Component({
  components: {
    OsHeader,
    OsCampaignSummary,
    OsDashButton,
    OsSheetHeader,
    OsSheetContent,
    OsSheet
  }
})
export default class Home extends Vue {
  get cardCols(): number {
    return 12;
  }

  get cardColsSm(): number {
    return 6;
  }

  get cardColsMd(): number {
    return this.$global.roles.isAdmin() ? 3 : 4;
  }
}
