import { RouteConfig } from 'vue-router';
import {
  AppUsersById,
  AppUsersCreate,
  AppUsersIndex
} from '@/pages/appusers/index';
import { AppUsers } from '@/pages';

const routeConfig: RouteConfig = {
  path: '/appusers',
  component: AppUsers,
  children: [
    {
      path: '',
      component: AppUsersIndex
    },
    {
      path: 'create',
      component: AppUsersCreate
    },
    {
      path: ':appuserId',
      component: AppUsersById
    },
    {
      path: '',
      redirect: '/'
    }
  ]
};
export default routeConfig;
