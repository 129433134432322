
import { Component, Prop, Vue } from 'vue-property-decorator';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsCloseButton from '@/components/core/OsCloseButton.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsSheetHeader from '@/components/core/sheet/OsSheetHeader.vue';
import OsInputCheckbox from '@/components/core/input/OsInputCheckbox.vue';
import { IRecipient } from '@/models/application/IRecipient';
import OsRecipientForm from '@/components/shared/recipients/OsRecipientForm.vue';

@Component({
  components: {
    OsRecipientForm,
    OsInputCheckbox,
    OsSheetHeader,
    OsToolbar,
    OsCloseButton,
    OsSheetContent,
    OsSheet,
    OsDatatable
  }
})
export default class OsRecipientsOverview extends Vue {
  @Prop({ required: true })
  customerId!: string;
  @Prop({ required: true })
  campaignId!: string;
  @Prop({ required: true })
  recipients!: IRecipient[];

  @Prop({ required: true, default: false })
  showCreateDialog!: boolean;

  @Prop({ default: 'Recipients' })
  textTitle!: string;
  @Prop({ default: 'create new recipient' })
  textCreateNewRecipient!: string;
  @Prop({ default: 'E-Mail-Address' })
  textTableEmailAddress!: string;
  @Prop({ default: 'Language' })
  textTableLanguagecode!: string;
  @Prop({ default: '' })
  textDelete!: string;

  get headers(): IExtendedDataTableHeader[] {
    return [
      {
        text: this.textTableEmailAddress,
        value: 'emailaddress'
      },
      {
        text: this.textTableLanguagecode,
        value: 'languagecode',
        width: '120px',
        formatter: (v) => {
          return this.$tc(`table.recipient.languagecode_option.${v}`);
        }
      },
      {
        text: this.textDelete,
        value: 'id',
        icon: 'mdi-delete-outline',
        width: '15px',
        sortable: false,
        align: 'center',
        action: async (recipient: IRecipient) => {
          this.$emit('deleteClicked', recipient);
        }
      }
    ];
  }

  onRecipientsCreate() {
    this.$emit('onRecipientsCreate');
  }
}
