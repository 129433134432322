
import { Component, Vue } from 'vue-property-decorator';
import CampaignTargetProfileById from '@/pages/campaigns/_id/target-profiles/_id.vue';
import userModule from '@/store/UserModule';
import { customersApi } from '@/apis';

@Component({
  components: {
    CampaignTargetProfileById
  }
})
export default class TargetProfileById extends Vue {
  campaignId = '';

  async created() {
    const targetProfile = await customersApi.getCustomerTargetProfileById(
      userModule.state.customer!.id,
      this.$route.params.targetprofileid
    );
    this.campaignId = targetProfile.campaignId;
  }
}
