
import { Component, ModelSync, Vue } from 'vue-property-decorator';
import OsInputRadioGroup from '@/components/core/input/OsInputRadioGroup.vue';
import OsLanguageSelection from '@/components/app/OsLanguageSelection.vue';
import { LanguageUtil } from '@/utils/LanguageUtil';
@Component({
  components: { OsLanguageSelection, OsInputRadioGroup }
})
export default class OsSurveyStepIntroLanguage extends Vue {
  @ModelSync('value', 'onModelChanged', { default: 'de' })
  internalValue!: any;

  async changeLanguage(e: string) {
    this.$emit('update:value', e);
    await LanguageUtil.setLanguageAsync(e);
  }

  mounted() {
    this.changeLanguage(this.internalValue);
  }
}
