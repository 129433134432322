
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICampaignSummary } from '@/models/application';
import OsCard from '@/components/core/card/OsCard.vue';
import OsCardText from '@/components/core/card/OsCardText.vue';
import OsCardTitle from '@/components/core/card/OsCardTitle.vue';
import OsCardSubTitle from '@/components/core/card/OsCardSubTitle.vue';
@Component({
  components: { OsCardSubTitle, OsCardTitle, OsCardText, OsCard }
})
export default class OsCampaignSummary extends Vue {
  @Prop({ required: true })
  campaignSummary!: ICampaignSummary;
}
