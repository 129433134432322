import { RouteConfig } from 'vue-router';
import Campaigns from '@/pages/Campaigns.vue';
import {
  CampaignById,
  CampaignByIdIndex,
  CampaignCreate,
  CampaignIndex,
  CampaignParticipantById,
  CampaignParticipantsIndex,
  CampaignTargetProfilesIndex,
  CampaignTargetProfileById
} from '@/pages/campaigns/index';
import CampaignAppUsersIndex from '@/pages/campaigns/_id/appusers/Index.vue';

const routeConfig: RouteConfig = {
  path: '/campaigns',
  component: Campaigns,
  children: [
    {
      path: '',
      component: CampaignIndex
    },
    {
      path: 'create',
      component: CampaignCreate
    },
    {
      path: ':campaignid',
      component: CampaignById,
      children: [
        {
          path: '',
          component: CampaignByIdIndex
        },
        {
          path: 'participants',
          component: CampaignParticipantsIndex
        },
        {
          path: 'appusers',
          component: CampaignAppUsersIndex
        },
        {
          path: 'participants/:participantId',
          component: CampaignParticipantById
        },
        {
          path: 'target-profiles',
          component: CampaignTargetProfilesIndex
        },
        {
          path: 'target-profiles/:targetprofileid',
          component: CampaignTargetProfileById
        }
      ]
    }
  ]
};
export default routeConfig;
