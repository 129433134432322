
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { ITargetProfile } from '@/models/application';
import OsDatatable from '@/components/core/OsDatatable.vue';
import { IExtendedDataTableHeader } from '@/models/core';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
import OsParticipantPdfDownloadBtn from '@/components/shared/participants/OsParticipantPdfDownloadBtn.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsCreateTargetProfileForm from '@/components/shared/target-profiles/OsCreateTargetProfileForm.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsCloseButton from '@/components/core/OsCloseButton.vue';
import { customersApi } from '@/apis';
import { GuidUtil } from '@/utils/GuidUtil';
import { IGlobalSearchTargetProfile } from '@/models/application/IGlobalSearchTargetProfile';
import OsCreateTargetProfileGuidedCreation from '@/components/shared/target-profiles/OsCreateTargetProfileGuidedCreation.vue';
import { DiscType } from '@/models/application/DiscType';

@Component({
  components: {
    OsCreateTargetProfileGuidedCreation,
    OsCloseButton,
    OsToolbar,
    OsCreateTargetProfileForm,
    OsSheetContent,
    OsSheet,
    OsParticipantPdfDownloadBtn,
    OsDatatable,
    OsAntesDisc
  }
})
export default class OsTargetProfileOverview extends Vue {
  @PropSync('targetProfiles', {
    required: false,
    default: () => []
  })
  internalTargetProfiles!: ITargetProfile[] | IGlobalSearchTargetProfile[];

  @Prop({ required: false })
  loading!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  showCampaignColumn!: boolean;

  @Prop({ default: true })
  enableCreate!: boolean;

  @Prop({ default: 'Target Profiles' })
  textHeader!: string;

  @Prop({ default: 'n/a' })
  textDiscNotAvailable!: string;

  @Prop({ default: 'Name' })
  textColumnName!: string;

  @Prop({ default: 'Campaign' })
  textCampaignName!: string;

  @Prop({ default: 'No Campaign assigned' })
  textNoCampaignAssigned!: string;

  @Prop({ default: null, required: true })
  customerId!: string;

  @Prop({ default: null, required: false })
  campaignId!: string;

  @Prop({ default: 'Create new target profile', required: false })
  textCreateNewTargetProfile!: string;

  showCreateFormDialog = false;
  showGuidedCreationDialog = false;

  guidedTourProfileName = '';

  $refs!: {
    guidedTour: any;
  };

  get headers(): IExtendedDataTableHeader[] {
    const tableHeaders = [
      {
        text: this.textColumnName,
        value: 'name'
      },
      {
        text: 'DISC',
        width: '70px',
        sortable: false,
        align: 'center',
        value: 'disc'
      }
    ] as IExtendedDataTableHeader[];

    if (this.showCampaignColumn) {
      tableHeaders.splice(1, 0, {
        text: this.textCampaignName,
        value: 'campaignname',
        formatter: (v) => {
          return v ?? this.textNoCampaignAssigned;
        },
        action: async (targetProfile: IGlobalSearchTargetProfile) => {
          await this.goToCampaign(targetProfile);
        }
      });
    }

    return tableHeaders;
  }

  onCreateClicked() {
    this.showCreateFormDialog = true;
  }

  async createTargetProfileOrShowTour(e: {
    name: string;
    useGuidedTour: boolean;
  }) {
    if (e.useGuidedTour) {
      this.$refs.guidedTour?.reset();
      this.guidedTourProfileName = e.name;
      this.showCreateFormDialog = false;
      this.showGuidedCreationDialog = true;

      return;
    }

    await this.createTargetProfile(e.name);
  }

  async guidedTourFinished(e: {
    name: string;
    values: { type: DiscType; value: number }[];
  }) {
    await this.createTargetProfile(e.name, {
      dValue: e.values.find((v) => v.type === DiscType.D)!.value,
      iValue: e.values.find((v) => v.type === DiscType.I)!.value,
      sValue: e.values.find((v) => v.type === DiscType.S)!.value,
      cValue: e.values.find((v) => v.type === DiscType.C)!.value
    });

    this.guidedTourProfileName = '';
    this.showCreateFormDialog = false;
    this.showGuidedCreationDialog = false;
  }

  async createTargetProfile(
    name: string,
    discValues: {
      dValue: number;
      iValue: number;
      sValue: number;
      cValue: number;
    } = {
      dValue: 0,
      iValue: 0,
      sValue: 0,
      cValue: 0
    }
  ) {
    const createdProfile = await customersApi.createTargetProfile(
      this.customerId,
      {
        id: GuidUtil.empty(),
        campaignId: this.campaignId ?? null,
        customerId: this.customerId,
        name: name,
        ...discValues
      }
    );

    if (this.campaignId) {
      await this.$router.push(
        `/campaigns/${this.campaignId}/target-profiles/${createdProfile.id}`
      );
    } else {
      await this.$router.push(`/target-profiles/${createdProfile.id}`);
    }
  }

  async goToCampaign(targetProfile: IGlobalSearchTargetProfile) {
    if (targetProfile.campaignId) {
      await this.$router.push(`/campaigns/${targetProfile.campaignId}`);
    }
  }
}
