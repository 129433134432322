
import { Component, Vue } from 'vue-property-decorator';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
import OsSheet from '@/components/core/sheet/OsSheet.vue';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsAntesDiscDummy from '@/components/chart/OsAntesDiscDummy.vue';
import OsParticipantForm from '@/components/shared/participants/OsParticipantForm.vue';
import {
  IParticipant,
  ISurveyCustomField,
  ISurveyCustomFieldAnswer
} from '@/models/application';
import { customersApi } from '@/apis';
import OsButton from '@/components/core/OsButton.vue';
import OsParticipantPdfDownloadBtn from '@/components/shared/participants/OsParticipantPdfDownloadBtn.vue';
import userModule from '@/store/UserModule';
import OsLoadingIndicator from '@/components/core/OsLoadingIndicator.vue';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
@Component({
  components: {
    OsLoadingIndicator,
    OsParticipantPdfDownloadBtn,
    OsButton,
    OsParticipantForm,
    OsAntesDiscDummy,
    OsSheetContent,
    OsSheet,
    OsAntesDisc
  }
})
export default class CampaignParticipantById extends Vue {
  appDirtyUtil: AppDirtyUtil = new AppDirtyUtil();
  participant: IParticipant | null = null;
  loading = false;

  customFieldAnswers: ISurveyCustomFieldAnswer[] = [];
  customFields: ISurveyCustomField[] = [];

  async mounted() {
    const customerId = userModule.state.customer!.id;
    const participantId = this.$route.params.participantId;

    this.customFields = await customersApi.getCustomFields(customerId);

    if (this.customFields && this.customFields.length > 0) {
      this.customFieldAnswers =
        await customersApi.getParticipantCustomFieldAnswersById(
          customerId,
          participantId
        );
    }

    this.participant = await customersApi.getParticipantById(
      customerId,
      participantId
    );
  }

  async onSave() {
    const customerId = userModule.state.customer!.id;

    this.loading = true;
    try {
      this.participant = await customersApi.updateParticipant(
        customerId,
        this.participant!
      );

      if (this.customFieldAnswers && this.customFieldAnswers.length > 0) {
        if (this.$global.roles.isAdmin()) {
          this.customFieldAnswers =
            await customersApi.setParticipantCustomFieldAnswersById(
              customerId,
              this.participant.id,
              this.customFieldAnswers
            );
        }
      }

      this.appDirtyUtil.init([this.participant, this.customFieldAnswers]);
    } finally {
      this.loading = false;
    }
  }

  undoClicked() {
    this.participant = this.appDirtyUtil.getOriginalObject()[0];
    this.customFieldAnswers = this.appDirtyUtil.getOriginalObject()[1];

    this.appDirtyUtil.init([this.participant, this.customFieldAnswers]);
  }
}
