
import { Component, Vue } from 'vue-property-decorator';
import { IHandledExceptionResponse } from '@/models/core/IHandledExceptionResponse';

@Component
export default class OsBusinessErrorResponseDialog extends Vue {
  visible = false;

  businessError: IHandledExceptionResponse = {
    message: '',
    dependingEntities: []
  };

  open(businessError: IHandledExceptionResponse) {
    this.businessError = businessError;
    this.visible = true;
  }

  close() {
    this.visible = false;
  }
}
