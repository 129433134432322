
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsEntityToolbar from '@/components/core/OsEntityToolbar.vue';
import OsToolbar from '@/components/core/OsToolbar.vue';
import OsEntityForm from '@/components/core/OsEntityForm.vue';
import { ICustomerSetting } from '@/models/application';
import { EntityFormFieldType, IEntityFormField } from '@/models/core';
import OsSheetContent from '@/components/core/sheet/OsSheetContent.vue';
import OsLogo from '@/components/core/OsLogo.vue';
import { Alignment } from '@/models/application/Alignment';
import OsButton from '@/components/core/OsButton.vue';
import _ from 'lodash';
import { customersApi } from '@/apis';

@Component({
  components: {
    OsButton,
    OsLogo,
    OsSheetContent,
    OsEntityForm,
    OsEntityToolbar,
    OsToolbar
  }
})
export default class OsCustomerSettingsForm extends Vue {
  @Prop({ required: true })
  customerId!: string;

  @PropSync('settings', { required: true })
  internalSettings!: ICustomerSetting;

  @Prop({ default: false, required: false })
  readonly!: boolean;

  @Prop({ default: 'Settings' })
  textTitle!: string;

  @Prop({ default: 'What kind of links should be used?' })
  textLinksTitle!: string;
  @Prop({ default: 'Max global Links' })
  textMaxGlobalLinks!: string;
  @Prop({ default: 'Max individual Links' })
  textMaxIndividualLinks!: string;
  @Prop({ default: 'Max App users' })
  textMaxAppUsers!: string;
  @Prop({ default: 'Customer logo' })
  textCustomerLogoTitle!: string;
  @Prop({ default: 'Customer other' })
  textCustomerOtherTitle!: string;
  @Prop({ default: 'Logo position' })
  textCustomerLogoPosition!: string;
  @Prop({ default: 'left' })
  textAlignmentLeft!: string;
  @Prop({ default: 'right' })
  textAlignmentRight!: string;
  @Prop({ default: 'Use customer Logo on reports' })
  textUseCustomerLogoOnReport!: string;
  @Prop({ default: 'Use customer Address on report cover' })
  textUseCustomerAddressOnReport!: string;
  @Prop({ default: 'Use customer Logo in E-Mail Templates' })
  textUseCustomerLogoOnEmailTemplate!: string;
  @Prop({ default: 'Save Logo Settings' })
  textButtonSaveLogoSettings!: string;

  editableSettings: ICustomerSetting | null = null;

  mounted() {
    this.initEditableSettings();
  }

  @Watch('internalSettings')
  onInternalValueChanged() {
    this.initEditableSettings();
  }

  initEditableSettings() {
    this.editableSettings = _.clone(this.internalSettings);
  }

  get linksFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.number,
        label: this.textMaxGlobalLinks,
        propName: 'maxGlobalLinkAmount',
        smCols: 4,
        lgCols: 3
      },
      {
        type: EntityFormFieldType.number,
        label: this.textMaxIndividualLinks,
        propName: 'maxIndividualLinkAmount',
        smCols: 4,
        lgCols: 3
      },
      {
        type: EntityFormFieldType.number,
        label: this.textMaxAppUsers,
        propName: 'maxAppuserAmount',
        smCols: 4,
        lgCols: 3
      }
    ];
  }

  get logoFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.checkbox,
        label: this.textUseCustomerLogoOnReport,
        propName: 'useBrandedReports',
        smCols: 12,
        mdCols: 4,
        lgCols: 3
      },
      {
        type: EntityFormFieldType.radio,
        label: this.textCustomerLogoPosition,
        propName: 'logoAlignment',
        smCols: 12,
        mdCols: 12,
        required: true,
        radioAsRow: true,
        items: [
          {
            key: this.textAlignmentLeft,
            value: Alignment.left
          },
          {
            key: this.textAlignmentRight,
            value: Alignment.right
          }
        ]
      }
    ];
  }

  get otherFields(): IEntityFormField[] {
    return [
      {
        type: EntityFormFieldType.checkbox,
        label: this.textUseCustomerAddressOnReport,
        propName: 'useAddressInReports',
        smCols: 12,
        mdCols: 4,
        lgCols: 3
      }
    ];
  }

  get editableSettingsAreDirty() {
    return (
      this.editableSettings &&
      (this.editableSettings.useBrandedReports !=
        this.internalSettings.useBrandedReports ||
        this.editableSettings.logoAlignment !=
          this.internalSettings.logoAlignment)
    );
  }

  get isSaveLogoVisible() {
    return (
      this.editableSettings &&
      this.internalSettings &&
      this.internalSettings.logoEnabled &&
      this.editableSettingsAreDirty
    );
  }

  async saveCustomerLogoSettings() {
    if (!this.editableSettings) {
      return;
    }

    try {
      const result = await customersApi.setSettings(
        this.customerId,
        this.editableSettings
      );

      if (result) {
        this.internalSettings.logoAlignment = result.logoAlignment;
        this.internalSettings.useBrandedReports = result.useBrandedReports;
      }
    } finally {
      this.initEditableSettings();
    }
  }
}
