import { StringValidationUtil } from '@/utils/StringValidationUtil';

export class TokenUtil {
  static tokenIsValid(value: string): boolean {
    return (
      !StringValidationUtil.isNullOrWhitespace(value) &&
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        value
      )
    );
  }
}
