
import { Component, Vue } from 'vue-property-decorator';
import CampaignParticipantsIndex from '@/pages/campaigns/_id/participants/Index.vue';
import OsParticipantOverview from '@/components/shared/participants/OsParticipantOverview.vue';
import { IGlobalSearchParticipant } from '@/models/application';
import userModule from '@/store/UserModule';
import { participantsApi } from '@/apis';
import OsParticipantGlobalOverview from '@/components/shared/participants/OsParticipantGlobalOverview.vue';
@Component({
  components: {
    OsParticipantGlobalOverview,
    OsParticipantOverview,
    CampaignParticipantsIndex
  }
})
export default class ParticipantsIndex extends Vue {
  customerId!: string;
  participants: IGlobalSearchParticipant[] = [];
  loading = false;

  async created() {
    this.customerId = userModule.state.customer!.id;
    this.participants = await participantsApi.getByCustomerId(
      userModule.state.customer!.id
    );
  }

  rowClicked(selected: any) {
    this.$router.push(`/participants/${selected.id}`);
  }
}
