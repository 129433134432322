import { BaseApi } from '@/apis/BaseApi';
import {
  ICreateSurveyParticipantRequest,
  IParticipant,
  IParticipantAnswer,
  ITokenResult,
  Recipientgroup
} from '@/models/application';
import { ISurvey } from '@/models/application/ISurvey';
import { IBaseApiResponse } from '@/models/core';

export class SurveysApi extends BaseApi {
  constructor() {
    super('surveys');
  }

  async getSurveyResultByToken(
    token: string
  ): Promise<IBaseApiResponse<ITokenResult>> {
    return await this.get<ITokenResult>(`?token=${token}`, false, false);
  }

  async getSurveyForCampaignId(
    campaignId: string,
    token: string,
    languagecode: string
  ): Promise<ISurvey> {
    return (
      await this.get<ISurvey>(
        `/${campaignId}?token=${token}&languagecode=${languagecode}`,
        false,
        false
      )
    ).result;
  }

  async createParticipant(
    campaignId: string,
    token: string,
    participant: ICreateSurveyParticipantRequest
  ): Promise<IParticipant> {
    return (
      await this.post<IParticipant>(
        `/${campaignId}/participants?token=${token}`,
        participant,
        false,
        false
      )
    ).result;
  }

  async createSurveyResult(
    campaignId: string,
    token: string,
    answers: IParticipantAnswer[]
  ): Promise<Recipientgroup> {
    return (
      await this.post<Recipientgroup>(
        `/${campaignId}/result?token=${token}`,
        answers,
        false,
        false
      )
    ).result;
  }
}
