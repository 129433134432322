
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OsSheet extends Vue {
  @Prop({ default: undefined })
  minHeight!: string;

  @Prop({ default: undefined })
  height!: string;

  @Prop({ default: undefined })
  maxWidth!: string;

  @Prop({ default: undefined })
  dark!: boolean;

  @Prop({ default: undefined })
  light!: boolean;
}
