import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Login } from '@/pages';

import home from '@/router/home';
import campaigns from '@/router/campaigns';
import mycompany from '@/router/mycompany';
import appusers from '@/router/appusers';
import participants from '@/router/participants';
import myprofile from '@/router/myprofile';
import survey from '@/router/survey';
import store from '@/store';
import { IGlobalFunctions } from '@/types/IGlobalFunctions';
import { AppDirtyUtil } from '@/utils/AppDirtyUtil';
import i18n from '@/plugins/vuei18n';
import setPassword from '@/router/set-password';
import targetProfiles from '@/router/target-profiles';
import external from '@/router/external';
import register from '@/router/register';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'Root',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      anonymousAllowed: true
    }
  },
  setPassword,
  register,
  home,
  campaigns,
  mycompany,
  appusers,
  participants,
  targetProfiles,
  myprofile,
  survey,
  external
  // { path: '*', redirect: '/mycompany' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (!isAuthenticated && !to.meta?.anonymousAllowed) {
    next('/login');
    return;
  }

  if (AppDirtyUtil.isDirty) {
    const discardChanges = await (
      Vue.prototype.$global as IGlobalFunctions
    ).dialogs.confirm(
      i18n.tc('control.confirm_dialog.title'),
      i18n.tc('control.confirm_dialog.body')
    );

    if (discardChanges) {
      AppDirtyUtil.setDirty(false);
    } else {
      return;
    }
  }

  next();
});

export default router;
