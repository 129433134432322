
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OsAntesDisc from '@/components/chart/OsAntesDisc.vue';
@Component({
  components: { OsAntesDisc }
})
export default class OsAntesDiscEditorValidator extends Vue {
  @Prop({ required: true })
  d!: number;

  @Prop({ required: true })
  i!: number;

  @Prop({ required: true })
  s!: number;

  @Prop({ required: true })
  c!: number;

  @Prop({ default: false })
  editing!: boolean;

  @PropSync('isValid', { required: true, default: false })
  internalIsValid!: boolean;

  @Prop({ default: 'Miniumum one positive value required.' })
  textPositiveValueRequired!: string;

  @Prop({ default: 'Miniumum one negative value required.' })
  textNegativeValueRequired!: string;

  @Prop({ default: 'Profile' })
  textProfile!: string;

  oneIsPositive = false;
  oneIsNegative = false;

  get values(): { key: string; value: number }[] {
    return [
      { key: 'D', value: this.d },
      { key: 'I', value: this.i },
      { key: 'S', value: this.s },
      { key: 'C', value: this.c }
    ];
  }

  mounted() {
    this.onChange();
  }

  @Watch('d')
  @Watch('i')
  @Watch('s')
  @Watch('c')
  @Watch('editing')
  onChange() {
    this.oneIsPositive = this.values.findIndex((v) => v.value > 0) >= 0;
    this.oneIsNegative = this.values.findIndex((v) => v.value < 0) >= 0;

    let isValid = false;
    if (!this.editing && this.oneIsPositive && this.oneIsNegative) {
      isValid = true;
    }
    this.$emit('update:isValid', isValid);
    this.$emit('update:profile', this.profile.join(''));
  }

  get profile(): string[] {
    const sorted = this.values
      .filter((v) => v.value >= 0)
      .sort((v1, v2) => (v1.value > v2.value ? -1 : 1));

    return sorted.map((v) => v.key);
  }

  get profileFormatted(): string {
    return this.profile.join(' / ');
  }
}
