
import { Component, ModelSync, Prop, Vue, Watch } from 'vue-property-decorator';
import { customersApi, metadataApi } from '@/apis';
import userModule from '@/store/UserModule';
import { StringValidationUtil } from '@/utils/StringValidationUtil';
import OsInputRadioGroup from '@/components/core/input/OsInputRadioGroup.vue';
import { ICampaign, IQuestionnaire } from '@/models/application';
import { ILookupAsRadio } from '@/models/core/ILookupAsRadio';

@Component({
  components: { OsInputRadioGroup }
})
export default class OsInputLookup extends Vue {
  @ModelSync('value', 'changed', { required: true, default: '' })
  internalValue!: string | ILookupAsRadio;

  @Prop({ required: true, default: '' })
  label!: string;
  @Prop({ required: false, default: false })
  readonly!: string;
  @Prop({ required: true, default: '' })
  entityName!: string;
  @Prop({ required: false, default: false })
  required!: boolean;
  @Prop({ required: false, default: () => ['name'] })
  searchableFields!: string[];
  items: { name: string; id: string; disabled?: boolean }[] = [];

  // radio
  @Prop({ required: false, default: false })
  lookupAsRadio!: boolean;
  @Prop({ required: false, default: false })
  row!: boolean;
  radioItems: ILookupAsRadio[] = [];

  async mounted() {
    if (this.lookupAsRadio) {
      await this.initPossibleRadioValues();
    } else {
      await this.initPossibleValues();
    }
  }

  @Watch('$store.state.user.appuser.languagecode')
  onLanguagecodeChanged() {
    this.initPossibleRadioValues();
  }

  async getSelectedQuestionnaire(): Promise<IQuestionnaire> {
    const allQuestionnaires = await metadataApi.getQuestionnaires();
    const selectedQuestionnaireIndex = allQuestionnaires.findIndex(
      (i) => i.id === this.internalValue
    );
    return allQuestionnaires[selectedQuestionnaireIndex];
  }

  async getQuestionnaires(): Promise<IQuestionnaire[]> {
    return await customersApi.getQuestionnaires(userModule.state.customer!.id);
  }

  async getCampaigns(): Promise<ICampaign[]> {
    return await customersApi.getCampaigns(userModule.state.customer!.id);
  }

  get rules(): any {
    const rules = [];

    if (this.required) {
      rules.push((v: string) => !!v || 'Pflichtfeld');
    }

    return rules;
  }

  // lookup autocomplete

  async initPossibleValues() {
    switch (this.entityName) {
      case 'questionnaire':
        this.items = (await this.getQuestionnaires()).map((v) => {
          return {
            name: v.title,
            id: v.id
          };
        });
        if (
          !StringValidationUtil.isNullOrWhitespace(
            this.internalValue as string
          ) &&
          this.items.findIndex((i) => i.id === this.internalValue) < 0
        ) {
          const selectedQuestionnaire = await this.getSelectedQuestionnaire();
          this.items.push({
            name: selectedQuestionnaire.title,
            id: this.internalValue as string,
            disabled: true
          });
        }
        return;

      case 'campaign':
        this.items = (await this.getCampaigns()).map((v) => {
          return {
            name: v.title,
            id: v.id
          };
        });
        if (
          !StringValidationUtil.isNullOrWhitespace(
            this.internalValue as string
          ) &&
          this.items.findIndex((i) => i.id === this.internalValue) < 0
        ) {
          const selectedQuestionnaire = await this.getSelectedQuestionnaire();
          this.items.push({
            name: selectedQuestionnaire.title,
            id: this.internalValue as string,
            disabled: true
          });
        }
        return;
    }
  }

  filter(item: any, queryText: string) {
    let values: string[] = [];
    let searchText = queryText.toLowerCase();

    for (let f of this.searchableFields) {
      values.push(item[f].toLowerCase());
    }

    return values.some((v) => v.indexOf(searchText) > -1);
  }

  getAutoCompleteLabel(v: { name: string; id: string }) {
    return v.name;
  }

  // lookup radio

  async initPossibleRadioValues() {
    switch (this.entityName) {
      case 'questionnaire':
        this.radioItems = (await this.getQuestionnaires()).map((q) => {
          const label = this.getQuestionnaireRadioLabel(q);
          return {
            key: label.value,
            value: q.id,
            disabled: false,
            isHtml: label.isHtml
          };
        });
        if (
          !StringValidationUtil.isNullOrWhitespace(
            (this.internalValue as ILookupAsRadio)?.key
          ) &&
          this.radioItems.findIndex((i) => i.key === this.internalValue) < 0
        ) {
          const selectedQuestionnaire = await this.getSelectedQuestionnaire();
          const selectedQuestionnaireLabel = this.getQuestionnaireRadioLabel(
            selectedQuestionnaire
          );
          this.radioItems.push({
            key: selectedQuestionnaireLabel.value,
            value: (this.internalValue as ILookupAsRadio).key,
            disabled: true,
            isHtml: selectedQuestionnaireLabel.isHtml
          });
        }
        return;
    }
  }

  getQuestionnaireRadioLabel(questionnaire: IQuestionnaire): {
    value: string;
    isHtml: boolean;
  } {
    if (!StringValidationUtil.isNullOrWhitespace(questionnaire.description)) {
      return {
        value: `<span class="font-weight-bold mr-1">${
          questionnaire.title
        }</span>(${this.$tc(questionnaire.description)})`,
        isHtml: true
      };
    }
    return {
      value: questionnaire.title,
      isHtml: false
    };
  }
}
